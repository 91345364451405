/* eslint-disable */
import {
  Box,
  Button,
  Flex,
  Icon,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue
} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import { useMemo } from 'react';
import { MdCancel, MdCheckCircle } from 'react-icons/md';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import { useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';

export default function AgentsTable(props) {
  const { columnsData, tableData, live, action } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow, initialState } =
    tableInstance;
  initialState.pageSize = 101;

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const iconColor = useColorModeValue('secondaryGray.500', 'white');
  const borderColor = useColorModeValue('#162744', '#ECEFF1');
  const yesColor = useColorModeValue('green', 'green');
  const noColor = useColorModeValue('red', 'red');

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: 'scroll', lg: 'hidden' }}
      border={`1px solid ${borderColor}`}
    >
      <Flex px="25px" justify="space-between" mb="20px" align="center">
        <Text color={textColor} fontSize="22px" fontWeight="700" lineHeight="100%">
          {live ? 'Live Agents' : 'Suspended Agents'}
        </Text>
      </Flex>
      <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe="10px"
                  ps="10px"
                  key={index}
                  borderColor={borderColor}
                >
                  <Flex
                    justify="space-between"
                    align="center"
                    fontSize={{ sm: '10px', lg: '12px' }}
                    color="gray.400"
                    display="block"
                    textAlign="center"
                  >
                    {column.render('Header')}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                  let data = '';
                  if (
                    cell.column.Header === 'NAME' ||
                    cell.column.Header === 'EMAIL' ||
                    cell.column.Header === 'PHONE NUMBER'
                  ) {
                    data = (
                      <Text color={textColor} fontSize="sm" fontWeight="700" textAlign="center">
                        {cell.value}
                      </Text>
                    );
                  } else if (cell.column.Header === 'ID') {
                    data = (
                      <Box textAlign="center">
                        <NavLink
                          to="/admin/default"
                          color={textColor}
                          fontSize="sm"
                          fontWeight="700"
                          textDecoration="underline"
                        >
                          {cell.value}
                        </NavLink>
                      </Box>
                    );
                  } else if (cell.column.Header === 'STATUS') {
                    data = (
                      <Icon
                        w="24px"
                        h="24px"
                        me="5px"
                        color={cell.value ? 'green.500' : 'red.500'}
                        as={cell.value ? MdCheckCircle : MdCancel}
                      />
                    );
                  } else if (cell.column.Header === 'ACTION') {
                    const filtered = cell.row.allCells.filter((o) => o.column.Header === 'ID');
                    if (filtered.length == 1) {
                      data = (
                        <Button
                          color={live ? 'yellow.500' : 'green.500'}
                          onClick={() => action(filtered[0].value)}
                        >
                          {live ? 'SUSPEND' : 'RESUME'}
                        </Button>
                      );
                    } else {
                      data = (
                        <Button color={live ? 'yellow.500' : 'green.500'} disabled>
                          {live ? 'SUSPEND' : 'RESUME'}
                        </Button>
                      );
                    }
                  }
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: '14px' }}
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      borderColor="transparent"
                      textAlign="center"
                    >
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Card>
  );
}
