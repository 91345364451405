import { Box, Button, Flex, Input, Select, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { useEffect, useState } from 'react';
import { formatFundName, getFundsPricesByDay } from 'service/api';
import { getStrictDate } from 'utils/string';

export default function UnitsAddition(props) {
  const {
    isAllocation,
    includeUnits,
    fundOptions,
    funds,
    removeFund,
    addFund,
    editFundId,
    editFundNum,
    balances,
    type,
    units,
    date,
    changer
  } = props;

  const bgHover = useColorModeValue({ bg: 'blue.400' }, { bg: 'blue.400' });
  const inputBg = useColorModeValue('whiteAlpha.300', 'whiteAlpha.100');
  const inputText = useColorModeValue('gray.700', 'gray.100');
  const bgButton = useColorModeValue('#47abff', '#47ABFF');
  const buttonTextColor = useColorModeValue('#ECEFF1', '#162744');
  const borderColor = useColorModeValue('#162744', '#ECEFF1');

  const [values, setValues] = useState({});
  const [calculatedUnits, setCalculatedUnits] = useState({});
  const [prices, setPrices] = useState([]);

  const fetchPrices = async () => {
    try {
      const price = await getFundsPricesByDay(getStrictDate(date));
      setPrices(price);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPrices();
  }, [funds, date]);

  useEffect(() => {
    funds.forEach((fund) => {
      const value = getValue(fund, type);
      const balance = getBalance(fund, type);
      if (value !== undefined) {
        setValues((prevValues) => ({
          ...prevValues,
          [fund.id]: value
        }));
      }
      if (balance !== undefined) {
        setCalculatedUnits((prevValues) => ({
          ...prevValues,
          [fund.id]: balance
        }));
      }
    });
  }, [funds, type, prices, changer]);

  const getValue = (value, type) => {
    const id = value.id;
    const price = (id) => prices.find((p) => p['fund_id'] === id);
    let calculatedValue = 0;

    if (type === 'switchIn') {
      const unitsFlatted = units[0].flat();
      const unitsFlattedB = units[1].flat();
      const findedValueB = unitsFlattedB.find((e) => parseInt(e.id) === parseInt(id));

      const arrayUnits2 = unitsFlatted
        .map((el) => el.num * price(el.id)['bid_price'])
        .reduce((a, b) => a + (Number(b) / 100) * findedValueB.num, 0);

      calculatedValue = arrayUnits2.toFixed(2);
    } else {
      const units = value.num;
      if (units && price) {
        let currentFundPrice = price(value.id)['bid_price'];
        calculatedValue = (currentFundPrice * Number(units)).toFixed(2);
      }
    }

    return calculatedValue;
  };

  const getBalance = (value, type) => {
    const id = value.id;
    let calculatedValue = 0;
    const price = prices.find((p) => p.fund_id === id);

    if (type === 'switchIn') {
      let balance = balances[id] ? balances[id] : 0;
      const unitsFlattedB = units[1].flat();
      const findedValueB = unitsFlattedB.find((e) => parseInt(e.id) === parseInt(id));
      if (findedValueB && price) {
        const result = (findedValueB.num * values[id]) / price['bid_price'];
        calculatedValue = (balance + result).toFixed(2);
      }
    } else {
      const balance = balances[id];

      if (balance) {
        calculatedValue = parseFloat(balance).toFixed(2);
      } else {
        return 0;
      }
    }

    return calculatedValue;
  };

  const filterFundOptions = (currentId) => {
    const ids = funds.map((t) => parseInt(t.id));
    return fundOptions.filter((o) => parseInt(o) === currentId || !ids.includes(parseInt(o)));
  };

  const handleInputChange = (index, value) => {
    const isValid = type !== 'switchIn' || (/^\d*\.?\d{0,2}$/.test(value) && Number(value) <= 100);
    if (isValid || value === '') {
      editFundNum(index, value);
    }
  };

  const isValidNumber = (value) => {
    return value === '' || /^\d*\.?\d{0,2}$/.test(value);
  };

  return (
    <Card
      direction="column"
      w="100%"
      borderRadius="0px"
      overflowX={{ sm: 'scroll', lg: 'hidden' }}
      padding="1px"
    >
      <Flex direction="column">
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection="row"
          spacing={10}
          fontWeight="600"
        >
          <Box width="300px">Fund</Box>
          <Box width="250px">{isAllocation ? 'Allocation' : 'Units'}</Box>
          {includeUnits && <Box width="120px">{isAllocation ? 'Units' : 'Remaining Units'}</Box>}
          {includeUnits && <Box width="120px">Value</Box>}
        </Box>
        {funds.map((value, index) => (
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection="row"
            gap="20px"
            key={index}
            my="6px"
          >
            <Select
              width="300px"
              bg={inputBg}
              color={inputText}
              fontWeight="500"
              borderRadius="30px"
              fontSize="sm"
              value={Number(value.id)}
              onChange={(e) => editFundId(index, Number(e.target.value))}
              border="1px solid"
              borderColor={borderColor}
            >
              {filterFundOptions(value.id).map((fundOption) => (
                <option value={Number(fundOption)} key={fundOption}>
                  {formatFundName(fundOption)}
                </option>
              ))}
            </Select>
            <Input
              fontSize="sm"
              width="250px"
              bg={inputBg}
              color={inputText}
              fontWeight="500"
              _placeholder={{ color: 'gray.400', fontSize: '14px' }}
              borderRadius="30px"
              placeholder={isAllocation ? 'e.g. % of Allocation' : 'e.g. No. of Units'}
              value={value.num}
              onChange={(e) => handleInputChange(index, e.target.value)}
              isInvalid={type === 'switchIn' && (value.num > 100 || !isValidNumber(value.num))}
              border="1px solid"
              borderColor={type === 'switchIn' && value.num > 100 ? 'red.500' : borderColor}
            />
            {includeUnits && (
              <Flex width="120px" align="center">
                {calculatedUnits[value.id] || 0}
              </Flex>
            )}
            {includeUnits && (
              <Flex width="120px" align="center">
                {values[value.id] || 0}
              </Flex>
            )}
          </Box>
        ))}
      </Flex>
      <Flex mt="25px" justify="center" width="100%">
        <Button
          fontSize="16px"
          mx="8px"
          bg={bgButton}
          _hover={bgHover}
          onClick={addFund}
          disabled={
            funds.length >= fundOptions.length || (type === 'switchIn' && units[0].length === 0)
          }
          color={buttonTextColor}
        >
          +
        </Button>
        <Button
          variant="outline"
          fontSize="16px"
          mx="8px"
          onClick={removeFund}
          disabled={funds.length <= 0}
        >
          -
        </Button>
      </Flex>
    </Card>
  );
}
