import {
  Box,
  Button,
  Text,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  Flex,
  useColorModeValue
} from '@chakra-ui/react';

import React from 'react';
import { capitalizeFirstLetter } from 'utils/string';

export default function PremiumEdit(props) {
  const { premium, productName, premiumAmt, premiumDate, handlePremium, handlePremiumType } = props;
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const inputBg = useColorModeValue('whiteAlpha.300', 'whiteAlpha.100');
  const inputText = useColorModeValue('gray.700', 'gray.100');

  return (
    <Flex px="px" justify="space-between" mb="20px">
      <Box direction="column" w="100%" px="0px" margin="0px" borderRadius="8px">
        <Flex px="25px" justify="space-between">
          <Box
            direction="column"
            w="100%"
            px="0px"
            margin="8px 0"
            padding="8px 0"
            borderRadius="8px"
          >
            <Text
              color={textColor}
              fontSize="16px"
              fontWeight="700"
              lineHeight="100%"
              marginBottom="0.625rem"
            >
              Premium Amount
            </Text>

            <Text
              color={textColor}
              fontSize="16px"
              fontWeight="500"
              lineHeight="100%"
              marginBottom="0.625rem"
            >
              $ {premium.amount}
            </Text>
          </Box>

          <Box direction="column" w="100%" px="0px" margin="8px" padding="8px" borderRadius="8px">
            <Text
              color={textColor}
              fontSize="16px"
              fontWeight="700"
              lineHeight="100%"
              marginBottom="0.625rem"
            >
              Premium Date
            </Text>
            <Text
              color={textColor}
              fontSize="16px"
              fontWeight="500"
              lineHeight="100%"
              marginBottom="0.625rem"
            >
              {premiumDate}
            </Text>
          </Box>
        </Flex>

        <Flex px="25px" justify="space-between">
          <Box
            direction="column"
            w="100%"
            px="0px"
            margin="8px 0"
            padding="8px 0"
            borderRadius="8px"
          >
            <Text
              color={textColor}
              fontSize="16px"
              fontWeight="700"
              lineHeight="100%"
              marginBottom="0.625rem"
            >
              Input Product Name
            </Text>

            <Text
              color={textColor}
              fontSize="16px"
              fontWeight="500"
              lineHeight="100%"
              marginBottom="0.625rem"
            >
              {productName}
            </Text>
          </Box>

          <Box direction="column" w="100%" px="0px" margin="8px" padding="8px" borderRadius="8px">
            <Text
              color={textColor}
              fontSize="16px"
              fontWeight="700"
              lineHeight="100%"
              marginBottom="0.625rem"
            >
              Premium Frequency
            </Text>
            <Text
              color={textColor}
              fontSize="16px"
              fontWeight="500"
              lineHeight="100%"
              marginBottom="0.625rem"
            >
              {capitalizeFirstLetter(premium.frequency)}
            </Text>
          </Box>
        </Flex>

        <Flex px="25px" justify="space-between">
          <Box
            direction="column"
            w="100%"
            px="0px"
            margin="8px 0"
            padding="8px 0"
            borderRadius="8px"
          >
            <Text
              color={textColor}
              fontSize="16px"
              fontWeight="700"
              lineHeight="100%"
              marginBottom="0.625rem"
            >
              Premium Amount
            </Text>

            <InputGroup>
              <InputLeftAddon>$</InputLeftAddon>
              <Input
                fontSize="sm"
                bg={inputBg}
                color={inputText}
                fontWeight="500"
                _placeholder={{ color: 'gray.400', fontSize: '14px' }}
                borderRadius="30px"
                placeholder="100"
                onChange={handlePremium}
                value={premiumAmt}
              />
            </InputGroup>
          </Box>

          <Box direction="column" w="100%" px="0px" margin="8px" padding="8px" borderRadius="8px">
            <Text
              color={textColor}
              fontSize="16px"
              fontWeight="700"
              lineHeight="100%"
              marginBottom="0.625rem"
            >
              Premium Frequency
            </Text>
            <Select
              bg={inputBg}
              color={inputText}
              fontWeight="500"
              borderRadius="30px"
              fontSize="sm"
              mt="5px"
              defaultValue="monthly"
              onChange={handlePremiumType}
            >
              <option value="monthly">MONTHLY</option>
              <option value="annually">ANNUALLY</option>
              <option value="lumpsum">LUMP-SUM</option>
            </Select>
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
}
