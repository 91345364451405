import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue
} from '@chakra-ui/react';

const UploadModal = ({
  isOpen,
  onClose,
  onOpenSecondaryModal,
  getRootProps,
  getInputProps,
  textColor,
  helperTextColor,
  openFileDialog
}) => {
  const buttonTextColor = useColorModeValue('#ECEFF1', '#162744');
  const borderColor = useColorModeValue('#162744', '#ECEFF1');
  const uploadIconColor = useColorModeValue('#ECEFF1', '#162744');
  const bgUploadBlock = useColorModeValue('#162744', '#ECEFF1');

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        maxW="626px"
        width="90%"
        h="305px"
        borderRadius="20px"
        bg={uploadIconColor}
        border={`1px solid ${borderColor}`}
      >
        <ModalHeader pd="16px">
          <Flex direction="row" align="center" mt="5px">
            <Text color={textColor} fontSize="22px" fontWeight="700" lineHeight="100%" mr="9px">
              Upload product transactions
            </Text>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={onOpenSecondaryModal}
            >
              <g clipPath="url(#clip0)">
                <path
                  d="M14.3468 7.31853C14.039 7.04802 13.681 6.84106 13.2728 6.69772C12.8643 6.5547 12.4375 6.48303 11.9919 6.48303C11.525 6.48303 11.0902 6.55756 10.6872 6.70578C10.2838 6.85442 9.93125 7.06381 9.62895 7.33438C9.32666 7.60494 9.08806 7.93906 8.91308 8.33675C8.73795 8.73453 8.65039 9.17755 8.65039 9.66519H10.719C10.719 9.29417 10.8249 8.98114 11.0371 8.72638C11.2491 8.47203 11.5674 8.34491 11.9919 8.34491C12.3949 8.34491 12.7104 8.46964 12.9386 8.71869C13.1666 8.96816 13.2807 9.27847 13.2807 9.64944C13.2807 9.83009 13.2354 10.0103 13.1453 10.1905C13.0552 10.3712 12.9465 10.5461 12.8191 10.7154L11.8226 11.9919C11.6104 12.2677 11.037 13.0657 11.037 13.8817V14.3592H13.1056V14.0251C13.1056 13.8874 13.1319 13.744 13.1852 13.5953C13.238 13.4471 13.4877 13.0842 13.5728 12.9781L14.5695 11.6542C14.7923 11.3573 14.9776 11.0524 15.1265 10.7393C15.2749 10.4266 15.3492 10.0686 15.3492 9.66524C15.3492 9.15617 15.2589 8.70533 15.0786 8.31289C14.8983 7.92041 14.6543 7.58867 14.3468 7.31853Z"
                  fill="white"
                />
                <path d="M13.1694 15.4009H10.9736V17.517H13.1694V15.4009Z" fill="white" />
                <path
                  d="M12 0C5.37248 0 0 5.37258 0 12C0 18.6274 5.37248 24 12 24C18.6273 24 24 18.6274 24 12C24 5.37258 18.6273 0 12 0ZM12 21C7.0373 21 3 16.9626 3 12C3 7.03739 7.0373 3 12 3C16.9625 3 21 7.03739 21 12C21 16.9626 16.9625 21 12 21Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </Flex>
          <Text
            color={helperTextColor}
            fontSize="16px"
            fontWeight="500"
            lineHeight="100%"
            pt="0.625rem"
          >
            XYZ instructions and disclaimers
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody display="flex" justifyContent="center" p="0">
          <Flex
            {...getRootProps()}
            w="333px"
            h="177px"
            bg={bgUploadBlock}
            direction="column"
            justify="center"
            align="center"
            borderRadius="20px"
            cursor="pointer"
            border={`1px solid ${borderColor}`}
            onClick={openFileDialog}
          >
            <input {...getInputProps()} />
            <svg
              width="126"
              height="100"
              viewBox="0 0 126 100"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M117.6 61.3472C112.963 61.3472 109.2 64.7819 109.2 69.0139V84.3472H16.8V69.0139C16.8 64.7819 13.0368 61.3472 8.4 61.3472C3.7632 61.3472 0 64.7819 0 69.0139V95.8472C0 97.6834 2.1882 99.6805 4.2 99.6805H121.8C124.337 99.6805 126 97.9249 126 95.8472V69.0139C126 64.7819 122.237 61.3472 117.6 61.3472ZM46.2 30.6805H54.6V65.1805C54.6 69.4125 58.3632 72.8472 63 72.8472C67.6368 72.8472 71.4 69.4125 71.4 65.1805V30.6805H79.8C82.7568 30.6805 85.3692 31.0447 87.0198 29.5306C88.662 28.0241 88.662 25.5745 87.0198 24.0641L66.2592 1.09877C65.3772 0.293769 64.2138 -0.051362 63.0672 0.00613803C61.9164 -0.051362 60.7529 0.293769 59.8751 1.09877L39.1146 24.0641C37.4682 25.5745 37.4682 28.0241 39.1146 29.5306C40.761 31.0447 44.5536 30.6805 46.2 30.6805Z"
                fill={uploadIconColor}
              />
            </svg>
            <Text
              color={buttonTextColor}
              fontSize="16px"
              fontWeight="500"
              lineHeight="100%"
              pt="20px"
            >
              Drag and Drop HTML File
            </Text>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default UploadModal;
