import {
  Box,
  Button,
  Checkbox,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useColorModeValue
} from '@chakra-ui/react';
import { css } from '@emotion/react';

import calendarIconWhite from 'assets/img/icons/calendar.png';
import calendarIconBlack from 'assets/img/icons/calendarBlack.png';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { editClient, getClient } from 'service/api';
import { formatDateDDMMYYYY } from 'utils/string';

export default function ModifyClientDetailsModal(props) {
  const location = useLocation();
  const { isOpen, onClose } = props;

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const helperTextColor = useColorModeValue('secondaryGray.900', 'whiteAlpha.600');
  const modalColor = useColorModeValue('#ECEFF1', '#162744');
  const cardColor = useColorModeValue('#ECEFF1', '#162744');
  const inputBg = useColorModeValue('#ECEFF1', '#162744');
  const inputText = useColorModeValue('#162744', '#ECEFF1');
  const inputTextPlaceholder = useColorModeValue('secondaryGray.600', 'whiteAlpha.600');
  const calendarIcon = useColorModeValue(`${calendarIconBlack}`, `${calendarIconWhite}`);
  const borderColor = useColorModeValue('#162744', '#ECEFF1');
  const bgButton = useColorModeValue('#47abff', '#47ABFF');
  const bgHover = useColorModeValue({ bg: 'blue.400' }, { bg: 'blue.400' });
  const buttonTextColor = useColorModeValue('#ECEFF1', '#162744');

  const [clientName, setClientName] = useState('');
  const [clientBirthday, setClientBirthday] = useState('');
  const [clientIsSmoker, setClientIsSmoker] = useState(false);
  const [clientGender, setClientGender] = useState({ male: true, female: false });
  const [clientId, setClientId] = useState(0);

  useEffect(() => {
    const getClientInfo = async () => {
      let i = location.pathname.replace('/agent/client/', '');
      let x = parseInt(i);
      setClientId(x);
      const res = await getClient(x);
      setClientName(res['name']);
      setClientBirthday(formatDateDDMMYYYY(res['birthday']));
      setClientIsSmoker(res['isSmoker']);
      setClientGender({ male: res['gender'] === 'male', female: res['gender'] === 'female' });
    };
    getClientInfo();
  }, [location.pathname]);

  const handleSubmit = async () => {
    try {
      const selectedGender = Object.keys(clientGender).find((gender) => clientGender[gender]);

      const formattedBirthday = new Date(clientBirthday);
      const dateToJson = formattedBirthday.toJSON();

      await editClient(clientName, dateToJson, clientIsSmoker, selectedGender);

      onClose();
    } catch (err) {
      alert(`Error: ${err.message || 'An error occurred. Please try again.'}`);
    }
  };

  const handleChangeName = (event) => {
    setClientName(event.target.value);
  };

  const handleChangeBirthday = (event) => {
    setClientBirthday(event.target.value);
  };

  const handleCheckSmoker = (event) => {
    setClientIsSmoker(event.target.checked);
  };

  const handleCheckNonSmoker = (event) => {
    setClientIsSmoker(!event.target.checked);
  };

  const handleCheckGender = (event) => {
    const { name, checked } = event.target;

    setClientGender({
      male: name === 'female' && !checked ? true : name === 'male' ? checked : false,
      female: name === 'male' && !checked ? true : name === 'female' ? checked : false
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW="56rem" bg={modalColor} border={`1px solid ${borderColor}`}>
        <ModalHeader>
          <Text color={textColor} fontSize="22px" fontWeight="700" lineHeight="100%">
            Modify Existing Client Details
          </Text>
          <Text
            color={helperTextColor}
            fontSize="16px"
            fontWeight="500"
            lineHeight="100%"
            padding="0.625rem 0"
          >
            DISCLAIMER: DO NOT PROVIDE ANY SENSITIVE INFORMATION
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex px="25px" justify="space-between" mb="20px">
            <Box
              direction="column"
              w="100%"
              px="0px"
              margin="8px"
              padding="16px"
              borderRadius="8px"
              bg={cardColor}
              border={`1px solid ${borderColor}`}
            >
              <Text
                color={textColor}
                fontSize="16px"
                fontWeight="700"
                lineHeight="100%"
                marginBottom="0.625rem"
              >
                Name/Alias
              </Text>
              <Input
                fontSize="sm"
                bg={inputBg}
                color={inputText}
                fontWeight="500"
                _placeholder={{ color: 'gray.400', fontSize: '14px' }}
                borderRadius="30px"
                value={clientName}
                onChange={handleChangeName}
              />
            </Box>
            <Box
              direction="column"
              w="100%"
              px="0px"
              margin="8px"
              padding="16px"
              borderRadius="8px"
              bg={cardColor}
              border={`1px solid ${borderColor}`}
              css={css`
                input::-webkit-datetime-edit-day-field:focus,
                input::-webkit-datetime-edit-month-field:focus,
                input::-webkit-datetime-edit-year-field:focus {
                  color: ${inputText};
                  outline: none;
                }
                input[type='date']:focus {
                  color: ${inputTextPlaceholder};
                  outline: none;
                }
              `}
            >
              <Text
                color={textColor}
                fontSize="16px"
                fontWeight="700"
                lineHeight="100%"
                marginBottom="0.625rem"
              >
                Birthday
              </Text>
              <Input
                fontSize="sm"
                bg={inputBg}
                color={inputText}
                fontWeight="500"
                _placeholder={{ color: 'black', fontSize: '14px', backgroundColor: 'black' }}
                borderRadius="30px"
                placeholder="DD-MM-YYYY e.g. 20-01-1999"
                type="date"
                onChange={handleChangeBirthday}
                value={clientBirthday}
                css={css`
                  ::-webkit-calendar-picker-indicator {
                    background: url(${calendarIcon}) center/80% no-repeat;
                    color: white;
                  }
                  input {
                    color: black;
                  }
                `}
                isInvalid={clientBirthday === ''}
                border={clientBirthday === '' ? '1px solid #FC8181' : '1px solid'}
              />
            </Box>
          </Flex>
          <Flex px="25px" justify="space-between" mb="20px">
            <Box
              direction="column"
              w="100%"
              px="0px"
              margin="8px"
              padding="16px"
              borderRadius="8px"
              bg={cardColor}
              border={`1px solid ${borderColor}`}
            >
              <Text
                color={textColor}
                fontSize="16px"
                fontWeight="700"
                lineHeight="100%"
                marginBottom="0.625rem"
              >
                Gender
              </Text>
              <Stack spacing={2}>
                <Checkbox name="male" isChecked={clientGender.male} onChange={handleCheckGender}>
                  Male
                </Checkbox>
                <Checkbox
                  name="female"
                  isChecked={clientGender.female}
                  onChange={handleCheckGender}
                >
                  Female
                </Checkbox>
              </Stack>
            </Box>
            <Box
              direction="column"
              w="100%"
              px="0px"
              margin="8px"
              padding="16px"
              borderRadius="8px"
              bg={cardColor}
              border={`1px solid ${borderColor}`}
            >
              <Text
                color={textColor}
                fontSize="16px"
                fontWeight="700"
                lineHeight="100%"
                marginBottom="0.625rem"
              >
                Smoker Status
              </Text>
              <Stack spacing={2}>
                <Checkbox isChecked={clientIsSmoker} onChange={handleCheckSmoker}>
                  Smoker
                </Checkbox>
                <Checkbox isChecked={!clientIsSmoker} onChange={handleCheckNonSmoker}>
                  Non-Smoker
                </Checkbox>
              </Stack>
            </Box>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Flex width="100%" justifyContent="center">
            <Button variant="outline" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button bg={bgButton} _hover={bgHover} color={buttonTextColor} onClick={handleSubmit}>
              Confirm
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
