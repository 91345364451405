/* eslint-disable */

import React from 'react';

// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';
// Custom components
import DefaultAuth from 'layouts/auth/Default';
import NotifyModal from './NotifyModal';
// Assets
import { css } from '@emotion/react';
import illustration2 from 'assets/img/auth/Group 8.png';
import illustration from 'assets/img/auth/Group 9.png';
import calendarIconWhite from 'assets/img/icons/calendar.png';
import calendarIconBlack from 'assets/img/icons/calendarBlack.png';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import { signup } from 'service/api';
import { history } from 'service/history';

function SignUp() {
  const isAdmin = location.pathname.includes('admin');
  const textColor = useColorModeValue('navy.700', 'white.100');
  const textColorSecondary = 'gray.400';
  const brandStars = useColorModeValue('brand.500', 'brand.400');
  const inputBg = useColorModeValue('#ECEFF1', '#162744');
  const inputText = useColorModeValue('#162744', '#ECEFF1');
  const inputTextPlaceholder = useColorModeValue('secondaryGray.600', 'whiteAlpha.600');
  const calendarIcon = useColorModeValue(`${calendarIconBlack}`, `${calendarIconWhite}`);
  const buttonTextColor = useColorModeValue('#ECEFF1', '#162744');
  const bgButton = useColorModeValue('#47abff', '#47ABFF');
  const bgHover = useColorModeValue({ bg: 'blue.400' }, { bg: 'blue.400' });

  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const [firstname, setFirstname] = React.useState('');
  const [lastname, setLastname] = React.useState('');
  const [birthday, setBirthday] = React.useState('');
  const [number, setNumber] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [message, setMessage] = React.useState('');

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleFirstname = (event) => {
    setFirstname(event.target.value);
  };

  const handleLastname = (event) => {
    setLastname(event.target.value);
  };

  const handleBirthday = (event) => {
    setBirthday(event.target.value);
  };

  const handleNumber = (event) => {
    setNumber(event.target.value);
  };

  const handleEmail = (event) => {
    setEmail(event.target.value);
  };

  const handlePassword = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPassword = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleSignup = async () => {
    try {
      if (password !== confirmPassword) {
        setMessage('Passwords does not match');
        onOpen();
        return;
      } else if (birthday === '') {
        setMessage('Birthday not provided');
        onOpen();
        return;
      } else if (birthday === new Date().toISOString().substring(0, 10)) {
        setMessage('Birthday cannot be today');
        onOpen();
        return;
      } else if (firstname === '') {
        setMessage('First name not provided');
        onOpen();
        return;
      } else if (lastname === '') {
        setMessage('Last name not provided');
        onOpen();
        return;
      } else if (number === '') {
        setMessage('Phone number not provided');
        onOpen();
        return;
      }
      // TODO: Handle admin signup here as well
      await signup(firstname + ' ' + lastname, email, number, password);
      const loginPage = isAdmin
        ? `${process.env.PUBLIC_URL}/auth/sign-in-admin`
        : `${process.env.PUBLIC_URL}/auth/sign-in`;
      setMessage('Account creation successful');
      onOpen();
      const reload = () => {
        history.go(loginPage);
      };
      setTimeout(reload, 1000);
    } catch (err) {
      setMessage(err);
      onOpen();
    }
  };

  const illustrationImg = isAdmin ? illustration2 : illustration;

  return (
    <DefaultAuth illustrationBackground={illustrationImg} image={illustrationImg}>
      <Flex
        maxW={{ base: '100%', md: 'max-content' }}
        w="100%"
        mx={{ base: 'auto', lg: '0px' }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: '30px', md: '60px' }}
        px={{ base: '25px', md: '0px' }}
        mt={{ base: '40px', md: '60px' }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px" fontFamily="exo">
            Sign Up ({isAdmin ? 'Admin' : 'Agent'})
          </Heading>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: '100%', md: '420px' }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: 'auto', lg: 'unset' }}
          me="auto"
          mb={{ base: '20px', md: 'auto' }}
        >
          <FormControl>
            <Flex>
              <Box
                flexBasis="55%"
                marginRight="6px"
                css={css`
                  input::-webkit-datetime-edit-day-field:focus,
                  input::-webkit-datetime-edit-month-field:focus,
                  input::-webkit-datetime-edit-year-field:focus {
                    color: ${inputText};
                    outline: none;
                  }
                  input[type='date']:focus {
                    color: ${inputTextPlaceholder};
                    outline: none;
                  }
                `}
              >
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  First Name
                </FormLabel>
                <Input
                  isRequired={true}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: '0px', md: '0px' }}
                  placeholder="John"
                  mb="24px"
                  fontWeight="500"
                  size="lg"
                  onChange={handleFirstname}
                  value={firstname}
                />
              </Box>

              <Box flexBasis="45%">
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Last Name
                </FormLabel>
                <Input
                  isRequired={true}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: '0px', md: '0px' }}
                  placeholder="Doe"
                  mb="24px"
                  fontWeight="500"
                  size="lg"
                  onChange={handleLastname}
                  value={lastname}
                />
              </Box>
            </Flex>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Date Of Birth
            </FormLabel>
            <Input
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: '0px', md: '0px' }}
              type="date"
              placeholder="e.g. 20-12-1993"
              mb="24px"
              fontWeight="500"
              size="lg"
              onChange={handleBirthday}
              value={birthday}
              css={css`
                ::-webkit-calendar-picker-indicator {
                  background: url(${calendarIcon}) center/80% no-repeat;
                  color: black;
                }
              `}
            />

            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Phone Number
            </FormLabel>
            <Input
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: '0px', md: '0px' }}
              placeholder="e.g. 9999 0000"
              mb="24px"
              fontWeight="500"
              size="lg"
              onChange={handleNumber}
              value={number}
            />

            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Email<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: '0px', md: '0px' }}
              placeholder="sample@email.com"
              mb="24px"
              fontWeight="500"
              size="lg"
              onChange={handleEmail}
              value={email}
            />

            <FormLabel ms="4px" fontSize="sm" fontWeight="500" color={textColor} display="flex">
              Password<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size="md">
              <Input
                isRequired={true}
                fontSize="sm"
                placeholder="Min. 8 characters"
                mb="24px"
                size="lg"
                type={show ? 'text' : 'password'}
                variant="auth"
                onChange={handlePassword}
                value={password}
              />
              <InputRightElement display="flex" alignItems="center" mt="4px">
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: 'pointer' }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>

            <FormLabel ms="4px" fontSize="sm" fontWeight="500" color={textColor} display="flex">
              Confirm Password<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size="md">
              <Input
                isRequired={true}
                fontSize="sm"
                placeholder="Min. 8 characters"
                mb="24px"
                size="lg"
                type={show ? 'text' : 'password'}
                variant="auth"
                onChange={handleConfirmPassword}
                value={confirmPassword}
              />
              <InputRightElement display="flex" alignItems="center" mt="4px">
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: 'pointer' }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>
            <Button
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              mb="24px"
              color={buttonTextColor}
              onClick={handleSignup}
              bg={bgButton}
              _hover={bgHover}
            >
              Sign Up
            </Button>
          </FormControl>
        </Flex>
      </Flex>
      <NotifyModal isOpen={isOpen} onClose={onClose} message={message} />
    </DefaultAuth>
  );
}

export default SignUp;
