import { useEffect, useState } from 'react';

import './ProductForm.css';

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Select,
  Text,
  useColorModeValue,
  VStack
} from '@chakra-ui/react';

import { css } from '@emotion/react';
import calendarIconWhite from 'assets/img/icons/calendar.png';
import calendarIconBlack from 'assets/img/icons/calendarBlack.png';
import { formatFundName } from 'service/api';
import { isFloat } from 'utils/numbers';
import { ProductInfo } from '../../createClientProfile/components/AddProduct';
import './ConfigForm.css';

export class ProductInvestmentInfo {
  constructor(id, annualInvestment, frequency, startDate, endDate, apportionment) {
    this.id = id;
    this.annualInvestment = annualInvestment;
    this.frequency = frequency;
    this.startDate = startDate;
    this.endDate = endDate;
    this.apportionment = apportionment;
  }
}

export default function ProductForm(props) {
  const inputBg = useColorModeValue('#ECEFF1', '#162744');
  const bgButton = useColorModeValue('#47abff', '#47ABFF');
  const bgHover = useColorModeValue({ bg: 'blue.400' }, { bg: 'blue.400' });
  const buttonTextColor = useColorModeValue('#ECEFF1', '#162744');
  const textColor = useColorModeValue('secondaryGray.300', 'white');
  const borderColor = useColorModeValue('#162744', '#ECEFF1');
  const calendarIcon = useColorModeValue(`${calendarIconBlack}`, `${calendarIconWhite}`);

  const { productId, product, updateProduct, fundOptions, startDate, endDate } = props;

  const [funds, setFunds] = useState([]);
  const [newHeight, setNewHeight] = useState(350);

  useEffect(() => {
    const fetchData = async () => {
      let newFund = new ProductInfo(1, 0.0);
      setFunds([newFund]);
    };
    fetchData();
  }, []);

  const selectAllocation = (index, allocation, e) => {
    let newFunds = funds;
    let x = parseFloat(allocation);
    if (isNaN(x)) {
      x = '0';
    }
    newFunds[index].num = x;
    setFunds([...newFunds]);

    product.apportionment = newFunds;
    updateProduct(productId, product);
  };

  const updateAnnualInvestment = (annualInvestment) => {
    product.annualInvestment = annualInvestment;
    updateProduct(productId, product);
  };

  const updateFrequency = (frequency) => {
    product.frequency = frequency;
    updateProduct(productId, product);
  };

  const updateStartDate = (date) => {
    product.startDate = date;
    updateProduct(productId, product);
  };

  const updateEndDate = (date) => {
    product.endDate = date;
    updateProduct(productId, product);
  };

  const selectFund = (index, id) => {
    let newFunds = funds;
    newFunds[index].id = id;
    setFunds([...newFunds]);

    product.apportionment = newFunds;
    updateProduct(productId, product);
  };

  const filterFundOptions = (currentId) => {
    const ids = funds.map((t) => parseInt(t.id));
    const filtered = fundOptions.filter(
      (o) => parseInt(o) == currentId || !ids.includes(parseInt(o))
    );
    return filtered;
  };

  const addFund = () => {
    if (funds.length >= fundOptions.length) {
      return;
    }
    let newFund = new ProductInfo(getFirstUnselectedFundId(), 0.0);
    let newFunds = funds.concat(newFund);
    setFunds([...newFunds]);
    setNewHeight(newHeight + 85);

    product.apportionment = newFunds;
    updateProduct(productId, product);
  };

  const removeFund = () => {
    if (funds.length <= 1) {
      return;
    }
    funds.pop();
    setFunds([...funds]);
    setNewHeight(newHeight - 85);

    updateProduct(productId, product);
  };

  const getFirstUnselectedFundId = () => {
    const ids = funds.map((t) => parseInt(t.id));
    let i = 0;
    for (; i < fundOptions.length; i++) {
      if (!ids.includes(fundOptions[i])) {
        return fundOptions[i];
      }
    }
    return 0;
  };

  const formatDate = (date) => {
    if (date === '') {
      return 'undefined';
    }
    const splittedDate = date.split('-');
    let result = splittedDate[2] + '/' + splittedDate[1] + '/' + splittedDate[0];

    return result;
  };

  return (
    <Box
      pt={{ base: '130px', md: '80px', xl: '20px' }}
      pl={{ base: '20px', md: '40px', xl: '20px' }}
      marginTop={'100px'}
      borderRadius={'20px'}
      borderStyle={'solid'}
      borderWidth={'1px'}
      borderColor={borderColor}
      w={'100%'}
    >
      <VStack alignItems={'left'} width={'279px'}>
        <Text fontWeight={'700'} fontSize={'22px'} color={textColor}>
          Product {productId}
        </Text>
      </VStack>
      <VStack alignItems={'left'} width={'700px'} pt={'17px'}>
        <form id={`form${productId}`}>
          <HStack spacing={'11px'}>
            <FormControl maxwidth={'140px'}>
              <FormLabel marginBottom={'10px'} color={textColor}>
                Annual Investment
              </FormLabel>
              <Input
                type="number"
                name="annualPremium"
                color={'gray.400'}
                borderRadius={'100px'}
                fontWeight={'500'}
                bg={inputBg}
                placeholder="$"
                _placeholder={{ color: 'gray.400', fontSize: '14px', fontWeight: '700' }}
                width={'140px'}
                onChange={(e) => updateAnnualInvestment(e.target.value)}
                required
                disabled={props.disable}
                border={'1px solid'}
                borderColor={borderColor}
              ></Input>
            </FormControl>
            <FormControl>
              <FormLabel marginBottom={'10px'} color={textColor}>
                Frequency
              </FormLabel>
              <Select
                id="Frequency"
                form="productForm"
                fontWeight={'700'}
                color={'gray.400'}
                fontSize={'14px'}
                borderRadius={'100px'}
                bg={inputBg}
                width={'140px'}
                name="frequency"
                onChange={(e) => updateFrequency(e.target.value)}
                disabled={props.disable}
                required
                border={'1px solid'}
                borderColor={borderColor}
              >
                <option>Please select</option>
                <option value={'monthly'}>MONTHLY</option>
                <option value={'annually'}>ANNUALLY</option>
                <option value={'lump-sum'}>LUMP-SUM</option>
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel marginBottom={'10px'} color={textColor}>
                Start Date
              </FormLabel>
              <Input
                iconColor={'white'}
                type={productId != 1 ? 'text' : 'date'}
                name="startingYear"
                title="The start date will be the same as the product 1's"
                placeholder={startDate !== '' ? formatDate(startDate) : 'dd/mm/yyyy'}
                _placeholder={{ color: 'gray.400', fontSize: '16px' }}
                color={'gray.400'}
                fontSize={'14px'}
                borderRadius={'100px'}
                fontWeight={'700'}
                bg={inputBg}
                width={'165px'}
                onChange={(e) => updateStartDate(e.target.value)}
                disabled={productId != 1 || props.disable}
                required
                border={'1px solid'}
                borderColor={borderColor}
                css={css`
                  ::-webkit-calendar-picker-indicator {
                    background: url(${calendarIcon}) center/80% no-repeat;
                    color: black;
                  }
                `}
              ></Input>
            </FormControl>
            <FormControl>
              <FormLabel marginBottom={'10px'} color={textColor}>
                End date
              </FormLabel>
              <Input
                type={productId != 1 ? 'text' : 'date'}
                name="endingYear"
                bg={inputBg}
                title="The end date will be the same as the product 1's"
                placeholder={endDate !== '' ? formatDate(endDate) : 'dd/mm/yyyy'}
                _placeholder={{ color: 'gray.400', fontSize: '16px' }}
                borderRadius={'100px'}
                color={'gray.400'}
                fontSize={'14px'}
                fontWeight={'700'}
                width={'165px'}
                onChange={(e) => updateEndDate(e.target.value)}
                disabled={productId != 1 || props.disable}
                required
                border={'1px solid'}
                borderColor={borderColor}
                css={css`
                  ::-webkit-calendar-picker-indicator {
                    background: url(${calendarIcon}) center/80% no-repeat;
                    color: black;
                  }
                `}
              ></Input>
            </FormControl>
          </HStack>
          <HStack gap={'11px'} pt={'20px'}>
            <FormControl>
              {funds.map((element, index) => (
                <Flex key={index} gap={'15px'}>
                  <Box>
                    <Text marginBottom={'10px'} color={textColor}>{`Fund ${index + 1}`}</Text>
                    <Select
                      bg={inputBg}
                      color={'gray.400'}
                      fontWeight="700"
                      borderRadius="30px"
                      fontSize="14px"
                      value={element.id}
                      width={'400px'}
                      name={`fundName-${index + 1}`}
                      disabled={props.disable}
                      required
                      onChange={(e) => selectFund(index, e.target.value, e)}
                      border={'1px solid'}
                      borderColor={borderColor}
                    >
                      {filterFundOptions(element.id).map((fundOption) => {
                        if (fundOption !== 1) {
                          return (
                            <option value={fundOption} key={fundOption}>
                              {formatFundName(fundOption)}
                            </option>
                          );
                        }
                      })}
                    </Select>
                  </Box>
                  <FormControl>
                    <Text marginBottom={'10px'} color={textColor}>
                      Fund Allocation
                    </Text>
                    <Input
                      placeholder="e.g. 5.5%"
                      _placeholder={{ color: 'gray.400', fontSize: '14px', fontWeight: '700' }}
                      value={element.num !== 0 ? element.num : ''}
                      name={`allocation-${index + 1}`}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value === '' || (Number(value) <= 100 && Number(value) >= 0)) {
                          selectAllocation(index, value, e);
                        }
                      }}
                      isInvalid={!isFloat(element.num)}
                      type="number"
                      color={'gray.400'}
                      borderRadius={'100px'}
                      fontWeight={'500'}
                      bg={inputBg}
                      width={'140px'}
                      marginBottom={'10px'}
                      disabled={props.disable}
                      required
                      border={'1px solid'}
                      borderColor={borderColor}
                    />
                  </FormControl>
                </Flex>
              ))}
            </FormControl>
          </HStack>
          {funds.length < 32 && (
            <Flex my="25px" justify="left" width="100%">
              <Button
                fontSize="16px"
                mx="8px"
                bg={bgButton}
                _hover={bgHover}
                color={buttonTextColor}
                onClick={addFund}
                disabled={props.disabled || funds.length >= fundOptions.length}
                shadow={'none'}
              >
                +
              </Button>
              <Button
                variant={'outline'}
                fontSize="19px"
                mx="8px"
                onClick={removeFund}
                disabled={props.disabled || funds.length <= 1}
                color={'gray.400'}
                shadow={'none'}
              >
                -
              </Button>
            </Flex>
          )}
        </form>
      </VStack>
    </Box>
  );
}
