import {
  Button,
  Flex,
  Icon,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue
} from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';

// Custom components
import Card from 'components/card/Card';

// Assets
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import { MdOutlineCalendarToday } from 'react-icons/md';

export default function PerformingFundTable(props) {
  const {
    columnsData,
    tableData,
    type,
    lastUpdatedDate,
    handleWeekly,
    handleMonthly,
    handleYearly,
    handleYtd
  } = props;

  const [duration, setDuration] = useState(0);

  const handleDuration = (durationType) => {
    if (durationType == 0) {
      handleWeekly();
    } else if (durationType == 1) {
      handleMonthly();
    } else if (durationType == 2) {
      handleYearly();
    } else if (durationType == 3) {
      handleYtd();
    }
    setDuration(durationType);
  };

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const tableInstance = useTable(
    {
      columns,
      data
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow, initialState } =
    tableInstance;
  initialState.pageSize = 10; // only show top 10

  const textColor = useColorModeValue('#162744', '#ECEFF1');
  const textColorSecondary = useColorModeValue('#162744', '#ECEFF1');
  const boxBg = useColorModeValue('#DBDCEB', 'whiteAlpha.100');
  const boxBgSelected = useColorModeValue('secondaryGray.600', 'whiteAlpha.500');
  const borderColorCard = useColorModeValue('#162744', '#ECEFF1');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

  return (
    <Card
      border={`1px solid ${borderColorCard}`}
      direction="column"
      w="100%"
      pt="20px"
      p="0px"
      overflowX={{ sm: 'scroll', lg: 'hidden' }}
    >
      <Flex px="20px" justify="space-between" mb="10px" align="center">
        <Text color={textColor} fontSize="22px" fontWeight="700" lineHeight="100%">
          {`10 ${type} Performing Funds`}
        </Text>
        <Flex alignItems="center">
          {type === 'Best' ? (
            <Icon as={AiFillCaretUp} color="green.500" boxSize={8} />
          ) : (
            <Icon as={AiFillCaretDown} color="red.500" boxSize={8} />
          )}
        </Flex>
        <div>
          <Text
            color={textColor}
            fontSize="12px"
            fontWeight="700"
            lineHeight="100%"
            ml="8px"
            minWidth="108px"
          >
            Correct as of
          </Text>
          <Text
            color={textColor}
            fontSize="12px"
            fontWeight="700"
            lineHeight="100%"
            ml="8px"
            minWidth="108px"
          >
            {lastUpdatedDate}
          </Text>
        </div>
      </Flex>
      <Flex px="25px" justify="space-between" mb="10px" align="center">
        <Button
          bg={duration == 0 ? boxBgSelected : boxBg}
          fontSize="sm"
          fontWeight="500"
          color={textColorSecondary}
          borderRadius="7px"
          ml="6px"
          onClick={() => handleDuration(0)}
        >
          <Icon as={MdOutlineCalendarToday} color={textColorSecondary} me="4px" />1 week
        </Button>
        <Button
          bg={duration == 1 ? boxBgSelected : boxBg}
          fontSize="sm"
          fontWeight="500"
          color={textColorSecondary}
          borderRadius="7px"
          ml="6px"
          onClick={() => handleDuration(1)}
        >
          <Icon as={MdOutlineCalendarToday} color={textColorSecondary} me="4px" />1 month
        </Button>
        <Button
          bg={duration == 2 ? boxBgSelected : boxBg}
          fontSize="sm"
          fontWeight="500"
          color={textColorSecondary}
          borderRadius="7px"
          ml="6px"
          onClick={() => handleDuration(2)}
        >
          <Icon as={MdOutlineCalendarToday} color={textColorSecondary} me="4px" />1 Year
        </Button>
        <Button
          bg={duration == 3 ? boxBgSelected : boxBg}
          fontSize="sm"
          fontWeight="500"
          color={textColorSecondary}
          borderRadius="7px"
          ml="6px"
          onClick={() => handleDuration(3)}
        >
          <Icon as={MdOutlineCalendarToday} color={textColorSecondary} me="4px" />
          Year-to-date
        </Button>
      </Flex>
      <Table {...getTableProps()} mb="24px">
        <Thead borderBottom={'1px solid'} borderColor="#A0AEBF">
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe="10px"
                  ps="10px"
                  key={index}
                  border="none"
                >
                  <Flex
                    justify="space-between"
                    align="center"
                    fontSize={{ sm: '10px', lg: '12px' }}
                    color="#A0AEBF"
                    display="block"
                    textAlign="center"
                  >
                    {column.render('Header')}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()} border="none">
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                  let data = '';
                  if (cell.column.Header === 'RANK' || cell.column.Header === 'NAME') {
                    data = (
                      <Text color={textColor} fontSize="sm" fontWeight="700" textAlign="center">
                        {cell.value}
                      </Text>
                    );
                  } else if (cell.column.Header === 'CHANGE') {
                    data = (
                      <Text color={textColor} fontSize="sm" fontWeight="700" textAlign="center">
                        {cell.value}
                      </Text>
                    );
                  }
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: '14px' }}
                      maxH="30px !important"
                      py="8px"
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      borderColor="transparent"
                    >
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Card>
  );
}
