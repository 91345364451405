import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue
} from '@chakra-ui/react';

import { useEffect, useState } from 'react';

import toast, { Toaster } from 'react-hot-toast';
import { createAlert, getAgentAlerts, getFundIds, getSelfAgentId } from 'service/api';
import ConfigureAlerts from '../modalComponents/ConfigureAlerts';

export class Alert {
  constructor(fundId, price, edit, alertType = true, live = false) {
    this.fundId = fundId;
    this.price = price;
    this.alertType = alertType;
    this.edit = edit;
    this.live = live;
  }
}

export default function AlertsModal(props) {
  const { isOpen, onClose } = props;
  const [alerts, setAlerts] = useState([]);

  const [fundOptions, setFundOptions] = useState([]);
  const getFirstUnselectedFundId = () => {
    const ids = alerts.map((t) => parseInt(t.fundId));
    let i = 0;
    for (; i <= fundOptions.length; i++) {
      if (!ids.includes(fundOptions[i])) {
        return fundOptions[i];
      }
    }
    return 0;
  };

  const addAlert = () => {
    if (alerts.length > 32) {
      return;
    }
    let newAlert = new Alert(getFirstUnselectedFundId(), 0, true, true, false);
    let newAlerts = alerts.concat(newAlert);
    setAlerts(newAlerts);
  };

  useEffect(() => {
    addAlert();
  }, []);

  const deleteAlert = (index) => {
    if (alerts[index].live === true) {
      return;
    }
    let newAlerts = alerts;
    newAlerts.splice(index, 1);
    setAlerts([...newAlerts]);
  };

  const toggleAlert = (index) => {
    if (alerts[index].live === true) {
      return;
    }
    let newAlerts = alerts;
    newAlerts[index].alertType = !newAlerts[index].alertType;
    setAlerts([...newAlerts]);
  };

  const toggleEdit = (index) => {
    if (alerts[index].live === true) {
      return;
    }
    let newAlerts = alerts;
    newAlerts[index].edit = !newAlerts[index].edit;
    setAlerts([...newAlerts]);
  };

  const toggleLive = (index) => {
    return;
  };

  const editFundId = (index, id) => {
    let newAlerts = alerts;
    newAlerts[index].fundId = id;
    setAlerts([...newAlerts]);
  };

  const editFundPrice = (index, price) => {
    const x = parseFloat(price);
    if (isNaN(x)) {
      price = '0';
    }
    let newAlerts = alerts;
    newAlerts[index].price = price.replace(/^0+(?=\d)/, '').replace(/[^0-9.]/g, '');
    setAlerts([...newAlerts]);
  };

  const setAllAlertsLive = () => {
    let newAlerts = alerts;
    for (let i = 0; i < newAlerts.length; i++) {
      newAlerts[i].live = true;
    }

    setAlerts([...newAlerts]);
  };

  const handleSubmit = async () => {
    try {
      if (alerts.length > 0) {
        const res = await Promise.all(
          alerts.map((alert) => {
            if (alert.live === true) {
              return;
            }
            const price = parseFloat(alert.price);
            const fundId = parseInt(alert.fundId);
            return createAlert(fundId, alert.alertType, price, getSelfAgentId());
          })
        );
        toastSuccess('Alerts are successfully created!');
        window.location.reload(true);
      } else {
        toastError('Please ensure all fields are properly filled');
      }
    } catch (err) {
      console.log(err);
      toastError('Please ensure all fields are properly filled');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const res = await getFundIds();
      setFundOptions(res);
      const myAlerts = await getAgentAlerts(getSelfAgentId(), 100, 0);
      setAlerts(
        myAlerts.map((ele) => {
          return new Alert(ele['fundId'], ele['price'], false, ele['isUpDirection'], true);
        })
      );
    };
    fetchData();
  }, []);

  const cardColor = useColorModeValue('#ECEFF1', '#162744');
  const bgButton = useColorModeValue('#47abff', '#47ABFF');
  const bgHover = useColorModeValue({ bg: 'blue.400' }, { bg: 'blue.400' });
  const buttonTextColor = useColorModeValue('#ECEFF1', '#162744');
  const borderColor = useColorModeValue('#162744', '#ECEFF1');

  const toastSuccess = (message) => toast.success(message);
  const toastError = (message) => toast.error(message);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW="65%" bg={cardColor} border={`1px solid ${borderColor}`}>
        <ModalHeader>Configure Alerts</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ConfigureAlerts
            alerts={alerts}
            addAlert={addAlert}
            deleteAlert={deleteAlert}
            toggleEdit={toggleEdit}
            toggleAlert={toggleAlert}
            editFundId={editFundId}
            editFundPrice={editFundPrice}
            toggleLive={toggleLive}
            fundOptions={fundOptions}
          />
          <Toaster />
        </ModalBody>
        <ModalFooter>
          <Flex width="100%" justifyContent="center">
            <Button
              bg={bgButton}
              _hover={bgHover}
              color={buttonTextColor}
              mr={3}
              onClick={addAlert}
              disabled={alerts.length >= fundOptions.length}
            >
              +
            </Button>
            <Button
              variant="brand"
              bg={bgButton}
              _hover={bgHover}
              color={buttonTextColor}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
