import axios from 'axios';
import { history } from 'service/history';
import {
  clearTokens,
  getIdentity,
  getJWT,
  storeAdminTokens,
  storeJWT,
  storeTokens
} from 'service/token';

// const baseURL = 'http://localhost:8080/api/v1'; // Replace with your backend API URL
const baseURL = 'https://prod-backend.funddolphin.com/api/v1'; // Replace with your backend API URL

const api = axios.create({
  baseURL: baseURL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getJWT()}`
  }
});

api.interceptors.request.use((config) => {
  const token = getJWT();
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (401 === error.response.status) {
      clearTokens();
      history.go('/auth/sign-in');
    } else {
      return Promise.reject(error);
    }
  }
);

export const isValidEmail = (email) => {
  const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return pattern.test(email);
};

export const isValidOTP = (otp) => {
  const pattern = /^[0-9]{1,6}$/;
  return pattern.test(otp);
};

export const dateToString = (date) => {
  return date.toISOString().slice(0, 10);
};

export const addOrSubractDays = (startingDate, number, add) => {
  const newDate = new Date(startingDate); // Create a new Date object from startingDate

  if (add) {
    newDate.setDate(newDate.getDate() + number);
  } else {
    newDate.setDate(newDate.getDate() - number);
  }

  return newDate;
};

export const formatDate = (dateString) => {
  const dateObj = new Date(dateString);

  const year = dateObj.getFullYear();
  const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
  const day = ('0' + dateObj.getDate()).slice(-2);
  const hours = ('0' + dateObj.getHours()).slice(-2);
  const minutes = ('0' + dateObj.getMinutes()).slice(-2);

  const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}`;

  return formattedDate;
};

export const login = async (email = 'ACfunddolphin@gmail.com', password = 'FundDolphin12#') => {
  if (!isValidEmail(email)) {
    throw 'Invalid email';
  }
  const res = await api.post(
    `/profile/login`,
    JSON.stringify({
      email: email,
      password: password
    })
  );
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    const jwt = res.headers.getAuthorization().split(' ')[1];
    const identity = res['data']['data']['ID'];
    const name = res['data']['data']['name'];
    storeTokens(jwt, identity, name);
    return true;
  }
};

export const adminLogin = async (
  email = 'ACfunddolphin@gmail.com',
  password = 'FundDolphin12#'
) => {
  if (!isValidEmail(email)) {
    throw 'Invalid email';
  }
  const res = await api.post(
    `/admin/login`,
    JSON.stringify({
      email: email,
      password: password
    })
  );
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    const jwt = res.headers.getAuthorization().split(' ')[1];
    const identity = res['data']['data']['ID'];
    const name = res['data']['data']['name'];
    storeAdminTokens(jwt, identity, name);
    return true;
  }
};

export const signup = async (
  name = 'John Doe',
  email = 'ACfunddolphin@gmail.com',
  phoneNumber = '87654321',
  password = 'FundDolphin12#'
) => {
  if (!isValidEmail(email)) {
    throw 'Invalid email';
  }
  const res = await api.post(
    `/profile/signup`,
    JSON.stringify({
      name: name,
      email: email,
      phoneNumber: phoneNumber,
      password: password
    })
  );
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    return res['data']['data'];
  }
};

export const forget = async (email = 'ACfunddolphin@gmail.com') => {
  if (!isValidEmail(email)) {
    throw 'Invalid email';
  }
  const res = await api.post(
    `/profile/forget`,
    JSON.stringify({
      email: email
    })
  );
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    return res['data']['data'];
  }
};

export const validateOTP = async (email = 'ACfunddolphin@gmail.com', otp = 123456) => {
  if (!isValidEmail(email)) {
    throw 'Invalid email';
  }

  if (!isValidOTP(otp)) {
    throw 'Invalid OTP';
  }

  const res = await api.post(
    `/profile/loginotp`,
    JSON.stringify({
      email: email,
      otp: parseInt(otp)
    })
  );
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    const jwt = res.headers.getAuthorization().split(' ')[1];
    storeJWT(jwt);
    return res['data']['data'];
  }
};

export const changePassword = async (password = 'FundDolphin12#') => {
  const res = await api.post(
    `/profile/password`,
    JSON.stringify({
      password: password
    })
  );
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    clearTokens();
    return res['data']['data'];
  }
};

export const changeProfile = async (name = '', phoneNumber = '') => {
  const res = await api.post(
    `/profile/update`,
    JSON.stringify({
      name: name,
      phoneNumber: phoneNumber
    })
  );
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    clearTokens();
    return res['data']['data'];
  }
};

export const getSelfAgentId = () => {
  return parseInt(getIdentity());
};

export const getFund = async (name = 'ASEAN') => {
  const res = await api.get(`/fund?name=${name}`);
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    return res['data']['data'];
  }
};

export const getFundPrices = async (code = 1, after = '2022-09-20', before = '2022-12-31') => {
  const res = await api.get(`/fund/price?fundId=${code}&before=${before}&after=${after}`);
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    return res['data']['data'];
  }
};

export const getFundIds = async (limit = 100, offset = 0) => {
  const res = await api.get(`/funds?limit=${limit}&offset=${offset}`);
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    return res['data']['data'].map((ele) => ele['id']);
  }
};

export const getFunds = async (limit = 100, offset = 0) => {
  const res = await api.get(`/funds?limit=${limit}&offset=${offset}`);
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    return res['data']['data'];
  }
};

export const getFundIdsWithAppendedNullOption = async () => {
  const res = await getFundIds();

  return [0].concat(...res);
};

export const getFundsWithAppendedNullOption = async () => {
  const res = await getFunds();
  const nullOption = [{ id: 0, name: 'Null', code: '00', fundType: 'NULL', currency: '' }];
  return nullOption.concat(...res);
};

export const getFundsPriceLatest = async (limit = 100, offset = 0) => {
  const res = await api.get(`/funds/latest?limit=${limit}&offset=${offset}`);
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    return res['data']['data'];
  }
};

export const getFundsAveragePrice = async (id = 1) => {
  const res = await api.get(`/fund/average?fundId=${id}`);
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    return res['data'];
  }
};

export const getFundsPricesByDay = async (date) => {
  const res = await api.get(`/fund/bid-price?date=${date}`);
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    return res['data']['prices'];
  }
};

export const getFundsByPerformance = async (
  limit = 100,
  offset = 0,
  sortType = 'weekly',
  ascending = true
) => {
  const res = await api.get(
    `/funds/performance?limit=${limit}&offset=${offset}&sortType=${sortType}&ascending=${ascending}`
  );
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    return res['data']['data'];
  }
};

export const getAgent = async (id = 1) => {
  const res = await api.get(`/agent?id=${id}`);
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    return res['data']['data'];
  }
};

export const createAgent = async (
  name = 'Mojojo',
  email = 'pikachu@live.com',
  phone = '98765432',
  managerId = 1
) => {
  const res = await api.post(
    `/agent`,
    JSON.stringify({
      name: name,
      email: email,
      phoneNumber: phone,
      managerId: managerId
    })
  );
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    return res['data']['data'];
  }
};

export const getClient = async (id = 1) => {
  const res = await api.get(`/client?id=${id}`);
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    return res['data']['data'];
  }
};

export const getAgentClients = async (id = 1, limit = 100, offset = 0) => {
  const res = await api.get(`/client/agent?id=${id}&limit=${limit}&offset=${offset}`);
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    return res['data']['data'];
  }
};

export const getAgentClientsWithStats = async (id = 1, limit = 100, offset = 0) => {
  const res = await api.get(`/client/agent/stat?id=${id}&limit=${limit}&offset=${offset}`);
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    return res['data']['data'];
  }
};

export const createClient = async (
  name = 'Omicron',
  birthday = '2019-01-20',
  gender = 'Female',
  isSmoker = false,
  agentId = 1
) => {
  const res = await api.post(
    `/client`,
    JSON.stringify({
      name: name,
      birthday: birthday,
      gender: gender,
      isSmoker: isSmoker,
      agentId: agentId
    })
  );
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    return res['data']['data'];
  }
};

export const editClient = async (
  name = 'Omicron',
  clientId = 1,
  dateToJson = '2019-01-20',
  clientIsSmoker = false,
  selectedGender = 'male'
) => {
  const res = await api.post(
    `/client/edit`,
    JSON.stringify({
      name: name,
      id: clientId,
      birthday: dateToJson,
      isSmoker: clientIsSmoker,
      gender: selectedGender
    })
  );
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    return res['data']['data'];
  }
};

export const removeClient = async (clientId = 1) => {
  const res = await api.delete(`/client`, {
    data: {
      id: clientId
    }
  });
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    return res['data']['data'];
  }
};

export const getProduct = async (id = 1) => {
  const res = await api.get(`/product?id=${id}`);
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    return res['data']['data'];
  }
};

export const editProductName = async (name = 'Omicron', id = 1) => {
  const res = await api.put(`/product`, JSON.stringify({ name: name, id: id }));
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    return res['data']['data'];
  }
};

export const deleteProduct = async (id = 1) => {
  try {
    const res = await api.delete(`/product?id=${id}`);
    return res['data'];
  } catch (error) {
    console.log('Error deleting product', error);
  }
};

export const getProductWithStats = async (id = 1) => {
  const res = await api.get(`/product/stat/?id=${id}`);
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    return res['data']['data'];
  }
};

export const getClientProducts = async (id = 1, limit = 100, offset = 0) => {
  const res = await api.get(`/product/client?id=${id}&limit=${limit}&offset=${offset}`);
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    return res['data']['data'];
  }
};

export const getAgentProducts = async (id = 1, limit = 100, offset = 0) => {
  const res = await api.get(`/product/agent?id=${id}&limit=${limit}&offset=${offset}`);
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    return res['data']['data'];
  }
};

export const createProduct = async (
  name = 'Great Wealth Advantage',
  totalInvested = 1000,
  premiumAmount = 1000,
  premiumFrequency = 'monthly',
  premiumDate = '2023-03-20',
  premiumApportionment = { 2: 0.5, 1: 0.5 },
  clientId = 1,
  policyType = 'op0',
  bonus = 0
) => {
  const res = await api.post(
    `/product`,
    JSON.stringify({
      name: name,
      totalInvested: totalInvested,
      premiumAmount: premiumAmount,
      premiumFrequency: premiumFrequency,
      premiumDate: premiumDate,
      premiumApportionment: premiumApportionment,
      clientId: clientId,
      policyType: policyType,
      bonus: bonus
    })
  );
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    return res['data']['data'];
  }
};

export const createExistingProduct = async (
  name = 'Great Wealth Advantage',
  totalInvested = 1000,
  premiumAmount = 1000,
  premiumFrequency = 'monthly',
  premiumDate = '2023-03-20',
  premiumApportionment = { 2: 0.5, 1: 0.5 },
  balances = { 1: 100 },
  clientId = 1,
  policyType = 'op0',
  bonus = 0
) => {
  const res = await api.post(
    `/product/exist`,
    JSON.stringify({
      name: name,
      totalInvested: totalInvested,
      premiumAmount: premiumAmount,
      premiumFrequency: premiumFrequency,
      premiumDate: premiumDate,
      premiumApportionment: premiumApportionment,
      balances: balances,
      clientId: clientId,
      policyType: policyType,
      bonus: bonus
    })
  );
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    return res['data']['data'];
  }
};

export const editProduct = async (
  id = 1,
  name = 'Great Wealth Advantage',
  totalInvested = 1000,
  premiumAmount = 1000,
  premiumFrequency = 'monthly',
  premiumDate = '2023-03-20',
  premiumApportionment = { 2: 0.5, 1: 0.5 },
  balances = { 1: 100 },
  clientId = 1,
  policyType = 'op0',
  bonus = 0
) => {
  const res = await api.put(
    `/product`,
    JSON.stringify({
      id: id,
      name: name,
      totalInvested: totalInvested,
      premiumAmount: premiumAmount,
      premiumFrequency: premiumFrequency,
      premiumDate: premiumDate,
      premiumApportionment: premiumApportionment,
      balances: balances,
      clientId: clientId,
      policyType: policyType,
      bonus: bonus
    })
  );
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    return res['data']['data'];
  }
};

export const createTransactions = async (product_id = 1, client_id = 1, transactions = []) => {
  const res = await api.post(
    `/fundtx/batch?client_id=${client_id}&product_id=${product_id}`,
    transactions
  );
  if (res.data.code !== 200) {
    throw new Error(res.data.msg);
  } else {
    return res.data.data;
  }
};

export const getTransactions = async (client_id = 1, product_id = 1) => {
  const res = await api.get(`/fundtx?client_id=${client_id}&product_id=${product_id}`);
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    return res['data']['data'];
  }
};

export const editTransactions = async (product_id = 1, client_id = 1, transactions = []) => {
  const res = await api.put(
    `/fundtx/batch?client_id=${client_id}&product_id=${product_id}`,
    transactions
  );
  if (res.data.code !== 200) {
    throw new Error(res.data.msg);
  } else {
    return res.data.data;
  }
};

export const deleteTransaction = async () => {
  const res = await api.delete(`/fundtx/last`);
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    return res['data']['data'];
  }
};

// purchase 10 units of fund "01" using 1000 SGD, will fire off a transaction
export const buyFund = async (id = 1, amount = 1000, symbol = '01', quantity = 10) => {
  const res = await api.post(
    `/product/buy`,
    JSON.stringify({
      id: id,
      amount: amount,
      fund: {
        symbol: symbol,
        quantity: quantity
      }
    })
  );
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    return res['data']['data'];
  }
};

// sell 10 units of fund "01" and receiving 1000 SGD, will fire off a transaction
export const sellFund = async (id = 1, amount = 1000, symbol = '01', quantity = 10) => {
  const res = await api.post(
    `/product/sell`,
    JSON.stringify({
      id: id,
      amount: amount,
      fund: {
        symbol: symbol,
        quantity: quantity
      }
    })
  );
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    return res['data']['data'];
  }
};

// Deposit 1000 SGD into the product, will fire off a transaction
export const deposit = async (id = 1, amount = 1000, date = '2023-03-20') => {
  const res = await api.post(
    `/product/deposit`,
    JSON.stringify({
      id: id,
      amount: amount,
      date: date
    })
  );
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    return res['data']['data'];
  }
};

// Withdraw 1000 SGD from the product, will fire off a transaction
export const withdraw = async (id = 1, amount = 1000, date = '2023-03-20') => {
  const res = await api.post(
    `/product/withdraw`,
    JSON.stringify({
      id: id,
      amount: amount,
      date: date
    })
  );
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    return res['data']['data'];
  }
};

export const fundswitch = async (
  id = 1,
  switchout = { 1: 200, 2: 100 },
  switchin = { sgd: 0.5, 3: 0.5 },
  date = '2023-03-20'
) => {
  const res = await api.post(
    `/product/fundswitch`,
    JSON.stringify({
      id: id,
      sell: switchout,
      buy: switchin,
      date: date
    })
  );
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    return res['data']['data'];
  }
};

export const premiumApportionment = async (id = 1, apportionment = { sgd: 0.5, 3: 0.5 }) => {
  const res = await api.post(
    `/product/apportionment`,
    JSON.stringify({
      id: id,
      apportionment: apportionment
    })
  );
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    return res['data']['data'];
  }
};

export const reducePremium = async (id = 1, frequency = 'yearly', amount = 1000) => {
  const res = await api.post(
    `/product/premium`,
    JSON.stringify({
      id: id,
      frequency: frequency,
      amount: amount
    })
  );
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    return res['data']['data'];
  }
};

export const createAlert = async (fundId = 1, isUpDirection = true, price = 1.23, agentId = 1) => {
  const res = await api.post(
    `/alert`,
    JSON.stringify({
      fundId: fundId,
      isUpDirection: isUpDirection,
      price: price,
      agentId: agentId
    })
  );
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    return res['data']['data'];
  }
};

export const getAlert = async (id = 1) => {
  const res = await api.get(`/alert?id=${id}`);
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    return res['data']['data'];
  }
};

export const removeAlert = async (id = 1) => {
  const res = await api.delete(`/alert`, {
    data: {
      id: id
    }
  });
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    return res['data']['data'];
  }
};

export const getAgentAlerts = async (id = 1, limit = 100, offset = 0) => {
  const res = await api.get(`/alert/agent?id=${id}&limit=${limit}&offset=${offset}`);
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    return res['data']['data'];
  }
};

export const getTriggeredAgentAlerts = async (id = 1, limit = 100, offset = 0) => {
  const res = await api.get(`/alert/agent/trigger?id=${id}&limit=${limit}&offset=${offset}`);
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    return res['data']['data'];
  }
};

export const getTransaction = async (id = 1) => {
  const res = await api.get(`/transaction?id=${id}`);
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    return res['data']['data'];
  }
};

export const getProductTransactions = async (id = 1, limit = 100, offset = 0) => {
  const res = await api.get(`/transaction/product?id=${id}&limit=${limit}&offset=${offset}`);
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    return res['data']['data'];
  }
};

export const getClientTransactions = async (id = 1, limit = 1000, offset = 0) => {
  const res = await api.get(`/transaction/client?id=${id}&limit=${limit}&offset=${offset}`);
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    return res['data']['data'];
  }
};

export const getAgentTransactions = async (id = 1, limit = 100, offset = 0) => {
  const res = await api.get(`/transaction/agent?id=${id}&limit=${limit}&offset=${offset}`);
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    return res['data']['data'];
  }
};

export const deleteManuallTransaction = async (id = 1) => {
  const res = await api.delete(`/transaction`, {
    data: {
      id: id
    }
  });
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    return res['data']['data'];
  }
};

export const getAveragePrice = async (id = 1, fundId = 1) => {
  const res = await api.get(`/transaction/average?id=${id}&fundId=${fundId}`);
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    return res['data']['data'];
  }
};

export const getAveragePrices = async (id = 1, fundIds = [1]) => {
  const res = {};
  for (let i = 0; i < fundIds.length; i++) {
    const temp = await getAveragePrices(id, fundIds[i]);
    res[i] = temp['buyPrice'];
  }
  return res;
};

export const adminSignup = async (
  name = 'John Doe',
  email = 'ACfunddolphin@gmail.com',
  password = 'FundDolphin12#'
) => {
  if (!isValidEmail(email)) {
    throw 'Invalid email';
  }
  const res = await api.post(
    `/admin/signup`,
    JSON.stringify({
      name: name,
      email: email,
      phoneNumber: phoneNumber,
      password: password
    })
  );
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    return res['data']['data'];
  }
};

export const addWhitelist = async (emails = ['ACfunddolphin@gmail.com']) => {
  for (let i = 0; i < emails.length; i++) {
    if (!isValidEmail(emails[i])) {
      throw 'Invalid email';
    }
  }
  const res = await api.post(
    `/admin/whitelist`,
    JSON.stringify({
      emails: emails
    })
  );
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    return res['data']['data'];
  }
};

export const removeWhitelist = async (emails = ['ACfunddolphin@gmail.com']) => {
  for (let i = 0; i < emails.length; i++) {
    if (!isValidEmail(emails[i])) {
      throw 'Invalid email';
    }
  }
  const res = await api.delete(`/admin/whitelist`, {
    data: {
      emails: emails
    }
  });
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    return res['data']['data'];
  }
};

export const getWhitelist = async (limit = 100, offset = 0) => {
  const res = await api.get(`/admin/whitelist?limit=${limit}&offset=${offset}`);
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    return res['data']['data'];
  }
};

export const getRegisteredWhitelist = async (limit = 100, offset = 0) => {
  const res = await api.get(`/admin/registeredwhitelist?limit=${limit}&offset=${offset}`);
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    return res['data']['data'];
  }
};

export const getUnregisteredWhitelist = async (limit = 100, offset = 0) => {
  const res = await api.get(`/admin/unregisteredwhitelist?limit=${limit}&offset=${offset}`);
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    return res['data']['data'];
  }
};

export const getSuspendedAgents = async (limit = 100, offset = 0) => {
  const res = await api.get(`/agent/suspend?limit=${limit}&offset=${offset}`);
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    return res['data']['data'];
  }
};

export const getActiveAgents = async (limit = 100, offset = 0) => {
  const res = await api.get(`/agent/active?limit=${limit}&offset=${offset}`);
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    return res['data']['data'];
  }
};

export const suspendAgent = async (email = 'ACfunddolphin@gmail.com') => {
  if (!isValidEmail(email)) {
    throw 'Invalid email';
  }
  const res = await api.post(
    `/admin/suspend`,
    JSON.stringify({
      email: email
    })
  );
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    return res['data']['data'];
  }
};

export const unsuspendAgent = async (email = 'ACfunddolphin@gmail.com') => {
  if (!isValidEmail(email)) {
    throw 'Invalid email';
  }
  const res = await api.post(
    `/admin/unsuspend`,
    JSON.stringify({
      email: email
    })
  );
  if (res['data']['code'] !== 200) {
    throw res['data']['msg'];
  } else {
    return res['data']['data'];
  }
};

export const funds = [
  {
    ID: 1,
    Name: 'GreatLink Cash Fund',
    Code: '01',
    FundType: 'UNIT LINKED TYPE',
    Currency: ''
  },
  {
    ID: 2,
    Name: 'GreatLink ASEAN Growth Fund',
    Code: '02',
    FundType: 'UNIT LINKED TYPE',
    Currency: ''
  },
  {
    ID: 3,
    Name: 'GreatLink Asia Pacific Equity Fund',
    Code: '03',
    FundType: 'UNIT LINKED TYPE',
    Currency: ''
  },
  {
    ID: 4,
    Name: 'GreatLink Global Supreme Fund',
    Code: '04',
    FundType: 'UNIT LINKED TYPE',
    Currency: ''
  },
  {
    ID: 5,
    Name: 'GreatLink Asia High Dividend Equity Fund',
    Code: '05',
    FundType: 'UNIT LINKED TYPE',
    Currency: ''
  },
  {
    ID: 6,
    Name: 'GreatLink European Sustainable Equity Fund',
    Code: '06',
    FundType: 'UNIT LINKED TYPE',
    Currency: ''
  },
  {
    ID: 7,
    Name: 'GreatLink Global Equity Fund',
    Code: '07',
    FundType: 'UNIT LINKED TYPE',
    Currency: ''
  },
  {
    ID: 8,
    Name: 'GreatLink Global Optimum Fund',
    Code: '08',
    FundType: 'UNIT LINKED TYPE',
    Currency: ''
  },
  {
    ID: 9,
    Name: 'GreatLink Global Technology Fund',
    Code: '09',
    FundType: 'UNIT LINKED TYPE',
    Currency: ''
  },
  {
    ID: 10,
    Name: 'GreatLink Sustainable Global Thematic Fund',
    Code: '11',
    FundType: 'UNIT LINKED TYPE',
    Currency: ''
  },
  {
    ID: 11,
    Name: 'GreatLink Global Bond Fund',
    Code: '12',
    FundType: 'UNIT LINKED TYPE',
    Currency: ''
  },
  {
    ID: 12,
    Name: 'GreatLink Global Perspective Fund',
    Code: '16',
    FundType: 'UNIT LINKED TYPE',
    Currency: ''
  },
  {
    ID: 13,
    Name: 'GreatLink Far East ex Japan Equities Fund',
    Code: '17',
    FundType: 'UNIT LINKED TYPE',
    Currency: ''
  },
  {
    ID: 14,
    Name: 'GreatLink Singapore Equities Fund',
    Code: '18',
    FundType: 'UNIT LINKED TYPE',
    Currency: ''
  },
  {
    ID: 15,
    Name: 'GreatLink Global Equity Alpha Fund',
    Code: '19',
    FundType: 'UNIT LINKED TYPE',
    Currency: ''
  },
  {
    ID: 16,
    Name: 'GreatLink Short Duration Bond Fund',
    Code: '20',
    FundType: 'UNIT LINKED TYPE',
    Currency: ''
  },
  {
    ID: 17,
    Name: 'GreatLink Lifestyle Secure Portfolio',
    Code: '21',
    FundType: 'UNIT LINKED TYPE',
    Currency: ''
  },
  {
    ID: 18,
    Name: 'GreatLink Lifestyle Steady Portfolio',
    Code: '22',
    FundType: 'UNIT LINKED TYPE',
    Currency: ''
  },
  {
    ID: 19,
    Name: 'GreatLink Lifestyle Balanced Portfolio',
    Code: '23',
    FundType: 'UNIT LINKED TYPE',
    Currency: ''
  },
  {
    ID: 20,
    Name: 'GreatLink Lifestyle Progressive Portfolio',
    Code: '24',
    FundType: 'UNIT LINKED TYPE',
    Currency: ''
  },
  {
    ID: 21,
    Name: 'GreatLink Lifestyle Dynamic Portfolio',
    Code: '25',
    FundType: 'UNIT LINKED TYPE',
    Currency: ''
  },
  {
    ID: 22,
    Name: 'GreatLink Global Real Estate Securities Fund',
    Code: '26',
    FundType: 'UNIT LINKED TYPE',
    Currency: ''
  },
  {
    ID: 23,
    Name: 'GreatLink Lion Japan Growth Fund',
    Code: '31',
    FundType: 'UNIT LINKED TYPE',
    Currency: ''
  },
  {
    ID: 24,
    Name: 'GreatLink China Growth Fund',
    Code: '32',
    FundType: 'UNIT LINKED TYPE',
    Currency: ''
  },
  {
    ID: 25,
    Name: 'GreatLink Lion India Fund',
    Code: '33',
    FundType: 'UNIT LINKED TYPE',
    Currency: ''
  },
  {
    ID: 26,
    Name: 'GreatLink Income Focus Fund',
    Code: '34',
    FundType: 'UNIT LINKED TYPE',
    Currency: ''
  },
  {
    ID: 27,
    Name: 'GreatLink Lion Asian Balanced Fund',
    Code: '35',
    FundType: 'UNIT LINKED TYPE',
    Currency: ''
  },
  {
    ID: 28,
    Name: 'GreatLink Lion Vietnam Fund',
    Code: '36',
    FundType: 'UNIT LINKED TYPE',
    Currency: ''
  },
  {
    ID: 29,
    Name: 'GreatLink Global Emerging Markets Equity Fund',
    Code: '175',
    FundType: 'UNIT LINKED TYPE',
    Currency: ''
  },
  {
    ID: 30,
    Name: 'GreatLink Diversified Growth Portfolio',
    Code: '212',
    FundType: 'UNIT LINKED TYPE',
    Currency: ''
  },
  {
    ID: 31,
    Name: 'GreatLink Multi-Theme Equity Fund',
    Code: '213',
    FundType: 'UNIT LINKED TYPE',
    Currency: ''
  },
  {
    ID: 32,
    Name: 'Great Eastern Lion Dynamic Balanced Fund',
    Code: '214',
    FundType: 'UNIT LINKED TYPE',
    Currency: ''
  },
  {
    ID: 33,
    Name: 'Great Eastern Lion Dynamic Growth Fund',
    Code: '215',
    FundType: 'UNIT LINKED TYPE',
    Currency: ''
  },
  {
    ID: 34,
    Name: 'GreatLink Income Bond Fund',
    Code: '216',
    FundType: 'UNIT LINKED TYPE',
    Currency: ''
  },
  {
    ID: 35,
    Name: 'GreatLink International Health Care Fund',
    Code: '222',
    FundType: 'UNIT LINKED TYPE',
    Currency: ''
  },
  {
    ID: 36,
    Name: 'GreatLink Global Disruptive Innovation Fund',
    Code: '224',
    FundType: 'UNIT LINKED TYPE',
    Currency: ''
  },
  {
    ID: 37,
    Name: 'GreatLink US Income and Growth Fund',
    Code: '225',
    FundType: 'UNIT LINKED TYPE',
    Currency: ''
  },
  {
    ID: 38,
    Name: 'GreatLink Multi-Sector Income Fund',
    Code: '226',
    FundType: 'UNIT LINKED TYPE',
    Currency: ''
  },
  {
    ID: 40,
    Name: 'GreatLink Asia Dividend Advantage Fund',
    Code: '227',
    FundType: 'UNIT LINKED TYPE',
    Currency: ''
  },
  {
    ID: 0,
    Name: 'Null',
    Code: '00',
    FundType: 'NULL',
    Currency: ''
  }
];

export const getFundName = (id = 1) => {
  for (let i = 0; i < funds.length; i++) {
    if (funds[i]['ID'] === parseInt(id)) {
      if (id < 10) {
        id = '0' + id;
      }
      let fundName = funds[i]['Name'];
      fundName = id + '-' + fundName;
      return fundName;
    }
  }
  return 'Unknown Fund';
};

export const getFundCode = (id = 1) => {
  for (let i = 0; i < funds.length; i++) {
    if (funds[i]['ID'] === parseInt(id)) {
      return funds[i]['Code'];
    }
  }
  return '00';
};

export const getFundCodeFromName = (name = 'Unknown Fund') => {
  for (let i = 0; i < funds.length; i++) {
    if (funds[i]['Name'] === name) {
      return funds[i]['Code'];
    }
  }
  return '00';
};

export const getFundIdFromCode = (id = '00') => {
  for (let i = 0; i < funds.length; i++) {
    if (funds[i]['ID'] === Number(id)) {
      return +funds[i]['Code'];
    }
  }
  return 0;
};

export const formatFundName = (id = 1) => {
  for (let i = 0; i < funds.length; i++) {
    if (parseInt(id) === 0) {
      return 'Please Select';
    } else if (funds[i]['ID'] === parseInt(id)) {
      return funds[i]['Code'] + '-' + funds[i]['Name'];
    }
  }
  return '00-Unknown Fund';
};

// Add more API functions here as needed
