import { Button, Stack, VStack, useColorModeValue } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import LineChart from './LineChart';
import ProductForm, { ProductInvestmentInfo } from './ProductForm';

import { Alert, AlertDescription, AlertIcon, AlertTitle } from '@chakra-ui/react';
import { getFundIdsWithAppendedNullOption, getFundPrices } from 'service/api';

function isDateValid(dateStr) {
  return !isNaN(new Date(dateStr));
}

function monthsBetweenDates(startDateStr, endDateStr) {
  const startDateObj = new Date(startDateStr);
  const endDateObj = new Date(endDateStr);

  // Calculate the difference in milliseconds between the two dates
  const timeDifference = endDateObj - startDateObj;

  // Calculate the number of months in the time difference
  // It assumes a month is 30.44 days on average.
  const monthsDifference = Math.floor(timeDifference / (30.44 * 24 * 60 * 60 * 1000));

  return monthsDifference;
}

const ConfigForm = () => {
  const bgHover = useColorModeValue({ bg: 'blue.500' }, { bg: 'blue.500' });
  const calculateBg = useColorModeValue({ bg: 'green.500' }, { bg: 'green.500' });
  const bgButton = useColorModeValue('#47abff', '#47ABFF');
  const buttonTextColor = useColorModeValue('#ECEFF1', '#162744');

  const [iproducts, setiproducts] = useState([new ProductInvestmentInfo(1, 0, '', '', '', [])]);
  const [fundOptions, setFundOptions] = useState([]);
  const [fundPrices, setFundPrices] = useState({});
  const [clicked, setClicked] = useState(false);
  const [AnnualPremiumAlert, setAnnualPremiumAlert] = useState(false);
  const [DatesAlert, setDatesAlert] = useState(false);
  const [datesErrorMesasge, setDatesErrorMessage] = useState('');
  const [allocatioAlert, setAllocationAlert] = useState(false);
  const [allocationErrorMessage, setAllocationErrorMessage] = useState('');

  const addProduct = () => {
    const newiproducts = [...iproducts];
    newiproducts.push(new ProductInvestmentInfo(newiproducts.length + 1, 0, '', '', '', []));
    setiproducts(newiproducts);
  };

  const removeProduct = () => {
    const newiproducts = [...iproducts];
    newiproducts.pop();
    setiproducts(newiproducts);
  };

  const updateProduct = (id, iproduct) => {
    let newiproducts = iproducts;
    newiproducts[id - 1] = iproduct; // index = id - 1
    setiproducts([...newiproducts]);
  };

  useEffect(() => {
    const fetchData = async () => {
      const res = await getFundIdsWithAppendedNullOption();
      setFundOptions(res);
    };
    fetchData();
  }, []);

  const getAllFunds = () => {
    let s = new Set();
    for (let i = 0; i < iproducts.length; i++) {
      let funds = iproducts[i].apportionment;
      for (let j = 0; j < funds.length; j++) {
        s.add(funds[j].id);
      }
    }
    return Array.from(s);
  };

  const fetchFundPrices = async () => {
    const fundIds = getAllFunds();
    const fundPrices = {};
    for (const fundId of fundIds) {
      fundPrices[fundId] = await getFundPrices(
        fundId,
        iproducts[0].startDate,
        iproducts[0].endDate
      );
    }
    return fundPrices;
  };

  const validateDate = () => {
    if (!isDateValid(iproducts[0].startDate)) {
      setDatesErrorMessage('An invalid start date has been provided');
      return false;
    }
    if (!isDateValid(iproducts[0].endDate)) {
      setDatesErrorMessage('An invalid end date has been provided');
      return false;
    }

    const starting = new Date(iproducts[0].startDate);
    const ending = new Date(iproducts[0].endDate);
    let monthDifference = monthsBetweenDates(starting, ending);

    if (starting > new Date() || ending > new Date()) {
      setDatesErrorMessage(
        'Unable to calculate as the date range is in the future. Please select a date range in the past in order to calculate prices'
      );
      return false;
    } else if (starting > ending) {
      setDatesErrorMessage("Starting date can't be later than ending date");
      return false;
    }

    for (let i = 0; i < iproducts.length; i++) {
      const frequency = iproducts[i].frequency;
      if (frequency === 'annually' && monthDifference < 24) {
        setDatesErrorMessage(
          "With 'annually' frequency the difference between years must be at least 2 years"
        );
        return false;
      } else if ((frequency === 'monthly' || frequency === 'lump-sum') && monthDifference < 2) {
        setDatesErrorMessage(
          "With 'monthly' frequency the difference between months must be at least 2 months"
        );
        return false;
      } else if (frequency === '' || frequency === 'Please select') {
        setDatesErrorMessage('Frequency not selected');
        return false;
      }
    }
    return true;
  };

  const validateInvestment = () => {
    for (let i = 0; i < iproducts.length; i++) {
      const annualInvestment = iproducts[i].annualInvestment;
      if (annualInvestment <= 0) {
        return false;
      }
    }
    return true;
  };

  const validateAllocation = () => {
    for (let i = 0; i < iproducts.length; i++) {
      const funds = iproducts[i].apportionment;
      if (funds.length < 1) {
        setAllocationErrorMessage('Allocations not selected');
        return false;
      }
      if (
        funds.find((fund) => {
          return parseInt(fund.id) === 0;
        }) !== undefined
      ) {
        setAllocationErrorMessage('Invalid funds selected');
        return false;
      }
      let sum = funds.reduce((acc, e) => acc + parseFloat(e.num), 0);
      if (sum < 99.99 || sum > 100.01) {
        setAllocationErrorMessage('Allocation value must be 100');
        return false;
      }
    }
    return true;
  };

  const validateTimeperiod = (fundPrices) => {
    let isValid = true;
    let min = 0;
    Object.keys(fundPrices).forEach(function (key) {
      if (fundPrices[key].length === 0) {
        setDatesErrorMessage('One of the funds do not exist yet in the provided dates');
        isValid = false;
      }
      if (min === 0) {
        min = fundPrices[key].length;
      }
      if (min !== fundPrices[key].length) {
        setDatesErrorMessage('One of the funds do not exist yet in the provided dates');
        isValid = false;
      }
    });

    return isValid;
  };

  const inputsValidation = async () => {
    setClicked(false);

    setDatesAlert(!validateDate());
    setAnnualPremiumAlert(!validateInvestment());
    setAllocationAlert(!validateAllocation());

    if (validateDate() && validateInvestment() && validateAllocation()) {
      const fundPrices_ = await fetchFundPrices();
      if (!validateTimeperiod(fundPrices_)) {
        setDatesAlert(true);
      } else {
        setFundPrices(fundPrices_);
        setClicked(true);
      }
    }
  };

  return (
    <VStack align="stretch" spacing={4}>
      {iproducts.map((product) => (
        <ProductForm
          key={product.id}
          productId={product.id}
          product={product}
          updateProduct={updateProduct}
          fundOptions={fundOptions}
          startDate={iproducts[0].startDate}
          endDate={iproducts[0].endDate}
          disable={false}
        ></ProductForm>
      ))}
      {AnnualPremiumAlert && (
        <Alert status="error">
          <AlertIcon />
          <AlertTitle>Annual Premium error:</AlertTitle>
          <AlertDescription>Annual premium amount must be greater than 0</AlertDescription>
        </Alert>
      )}
      {DatesAlert && (
        <Alert status="error">
          <AlertIcon />
          <AlertTitle>Date Error:</AlertTitle>
          <AlertDescription>{datesErrorMesasge}</AlertDescription>
        </Alert>
      )}
      {allocatioAlert && (
        <Alert status="error">
          <AlertIcon />
          <AlertTitle>Allocation error:</AlertTitle>
          <AlertDescription>{allocationErrorMessage}</AlertDescription>
        </Alert>
      )}
      <Stack
        spacing={'20px'}
        direction={'row'}
        marginTop={'30px'}
        marginLeft={'50px'}
        justifyContent={'center'}
      >
        <Button
          onClick={addProduct}
          bg={bgButton}
          color={buttonTextColor}
          _hover={bgHover}
          disabled={iproducts.length === 3}
          shadow={'none'}
        >
          Add new product
        </Button>
        <Button
          onClick={removeProduct}
          bg={bgButton}
          color={buttonTextColor}
          _hover={bgHover}
          disabled={iproducts.length === 1}
          shadow={'none'}
        >
          Remove product
        </Button>
        <Button _hover={calculateBg} bg={calculateBg} onClick={inputsValidation} shadow={'none'}>
          Calculate!
        </Button>
      </Stack>
      {clicked ? <LineChart iproducts={iproducts} fundPrices={fundPrices} /> : null}
    </VStack>
  );
};

export default ConfigForm;
