// Chakra imports
import { Box, Flex, Icon, Text } from '@chakra-ui/react';
import FixedPlugin from 'components/fixedPlugin/FixedPlugin';
import Footer from 'components/footer/FooterAuth';
import PropTypes from 'prop-types';

import { NavLink } from 'react-router-dom';

import { FaChevronLeft } from 'react-icons/fa';

function AuthIllustration(props) {
  const { children, illustrationBackground, isSignIn } = props;

  return (
    <Flex position="relative" h="max-content">
      <Flex
        h={{
          sm: 'initial',
          md: 'unset',
          lg: '100vh',
          xl: '97vh'
        }}
        w="100%"
        maxW={{ md: '66%', lg: '1313px' }}
        mx="auto"
        pt={{ sm: '50px', md: '0px' }}
        px={{ lg: '30px', xl: '0px' }}
        ps={{ xl: '70px' }}
        justifyContent="start"
        direction="column"
      >
        <NavLink
          to="/auth/sign-in"
          style={() => ({
            width: 'fit-content',
            marginTop: '40px'
          })}
        >
          {!isSignIn && (
            <Flex
              align="center"
              ps={{ base: '25px', lg: '0px' }}
              pt={{ lg: '0px', xl: '0px' }}
              w="fit-content"
            >
              <Icon as={FaChevronLeft} me="12px" h="13px" w="8px" color="secondaryGray.600" />
              <Text ms="0px" fontSize="sm" color="secondaryGray.600">
                Back to Sign In
              </Text>
            </Flex>
          )}
        </NavLink>
        <FixedPlugin left={{ base: 'calc(100% - 125px)', lg: 'calc(50% - 110px)' }} top="50px" />
        {children}
        <Box
          display={{ base: 'none', lg: 'block' }}
          minH="100vh"
          h="100vh"
          w={{ lg: '50vw', '2xl': '44vw' }}
          position="absolute"
          right="0px"
          borderBottomLeftRadius={{ lg: '120px', xl: '100px' }}
        >
          {/* <Flex
            bg={`url(${illustrationBackground})`}
            justify="center"
            align="end"
            w="100%"
            h="100vh"
            bgSize="cover"
            bgRepeat="no-repeat"
            bgPosition="50%"
            position="absolute"
          ></Flex> */}
          <img src={illustrationBackground} alt="auth" />
        </Box>
        <Footer />
      </Flex>
    </Flex>
  );
}
// PROPS

AuthIllustration.propTypes = {
  illustrationBackground: PropTypes.string,
  image: PropTypes.any
};

export default AuthIllustration;
