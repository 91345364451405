import { Box, Text, useColorModeValue } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useTable } from 'react-table';
import { getRoiFund } from 'utils/numbers';

const FundsOverview = ({
  fundsData,
  transactions,
  showWelcomeBonus,
  totalInvestmentValue,
  currentProduct
}) => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (Array.isArray(fundsData)) {
      setTableData(fundsData);
    } else {
      setTableData([]);
    }
  }, [fundsData]);

  let totalInvestmentAmount = 0;
  let totalInvestmentValueForProduct = totalInvestmentValue.find((el) => {
    return el['ID'] === currentProduct['ID'];
  });

  if (transactions.length > 0) {
    totalInvestmentAmount = transactions.reduce((acc, d) => {
      if (showWelcomeBonus) {
        if (
          d.applicationType === 'Net Investment Premium' ||
          d.applicationType === 'Welcome Bonus'
        ) {
          return acc + Number(d.amount);
        }
      } else {
        if (d.applicationType === 'Net Investment Premium') {
          return acc + Number(d.amount);
        }
      }
      return acc;
    }, 0);
  }

  let roi = getRoiFund(totalInvestmentAmount, totalInvestmentValueForProduct.assetValue);

  const columns = React.useMemo(
    () => [
      { Header: 'NO', accessor: 'no' },
      { Header: 'FUND', accessor: 'asset' },
      { Header: 'START DATE', accessor: 'start_date' },
      { Header: 'UNITS', accessor: 'units' },
      { Header: 'CURRENT VALUE', accessor: 'value' },
      { Header: 'CURRENT PRICE', accessor: 'current_price' },
      { Header: 'ROI', accessor: 'roi' },
      { Header: 'AVERAGE PRICE', accessor: 'average_price' }
    ],
    [tableData]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    data: tableData || [],
    columns,
    debug: true
  });

  const borderColorCard = useColorModeValue('#162744', '#ECEFF1');

  return (
    <Box w="100%" py="20px" borderRadius="20px" border={`1px solid ${borderColorCard}`}>
      <Text fontSize="22px" fontWeight="700" lineHeight="100%" mb="5px" ml="20px">
        Funds Overview
      </Text>
      <table {...getTableProps()} style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr key={index} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  key={column.id}
                  {...column.getHeaderProps()}
                  style={{
                    padding: '10px',
                    borderBottom: '1px solid #A0AEBF',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: '13px'
                  }}
                >
                  <Text color="#A0AEBF">{column.render('Header')}</Text>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows?.map((row, index) => {
            prepareRow(row);
            return (
              <tr key={index} {...row.getRowProps()}>
                {row.cells.map((cell, index) => (
                  <td
                    key={index}
                    {...cell.getCellProps()}
                    style={{
                      padding: '10px',
                      textAlign: 'center',
                      fontSize: '14px',
                      fontWeight: 'bold'
                    }}
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <Box px="20px" fontSize="18px" mt="25px">
        <Box mb="4px" display="flex" justifyContent="space-between" flexDirection="row">
          <Text>Total Investment Value:</Text>
          <Text>{totalInvestmentValueForProduct.assetValue.toFixed(2)}</Text>
        </Box>
        <Box mb="4px" display="flex" justifyContent="space-between" flexDirection="row">
          <Text>Total Investment Amount:</Text>
          <Text>{totalInvestmentAmount.toFixed(2)}</Text>
        </Box>
        <Box display="flex" justifyContent="space-between" flexDirection="row">
          <Text>Return on Investment (ROI): </Text>
          <Text>{`${roi.toFixed(2)}%`}</Text>
        </Box>
      </Box>
    </Box>
  );
};

export default FundsOverview;
