import {
  Box,
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spinner,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';

import { useEffect, useRef, useState } from 'react';
import { MdAddCircle, MdBarChart, MdDelete, MdEdit } from 'react-icons/md';
import { useHistory, useLocation } from 'react-router-dom';

import PriceChanges from 'views/agent/clientOverview/components/PriceChanges';
import SummaryPieCard from 'views/agent/clientOverview/components/SummaryPieCard';

import Card from 'components/card/Card';
import MiniStatistics from 'components/card/MiniStatistics';
import ConfirmModal from 'components/confirmModal/ConfirmModal';
import IconBox from 'components/icons/IconBox';
import BasicTableWithPagination from 'components/table/BasicTableWithPagination';
import toast from 'react-hot-toast';
import {
  deleteProduct,
  formatFundName,
  getClientProducts,
  getFundCode,
  getFundCodeFromName,
  getFundsAveragePrice,
  getProductWithStats,
  getTransactions,
  removeClient
} from 'service/api';
import { generateColors } from 'utils/colors';
import { getRoiFund, limitDpTo } from 'utils/numbers';
import { convertDateToRender } from 'utils/string';
import { columnsDataProducts } from 'views/agent/clientOverview/variables/columnsData';
import ImportClientForm from '../importClientProfile/components/ImportClientForm';
import AddProductModal from './components/AddProductModal';
import ModifyClientDetailsModal from './components/ModifyClientDetailsModal';
import ProductOverview from './components/ProductOverview';
import StartTransactionModal from './components/StartTransactionModal';
import { TransactionAuditModal } from './components/TransactionAuditHistory';
import TransactionModal from './components/TransactionModal';
import { columnsDataTransactions } from './variables/columnsData';
import { defaultPiechartData, fillPiechartData } from './variables/piechartData';
// import TransactionBarChart from './components/TransactionBarChart';
import { useReactToPrint } from 'react-to-print';
import FundsOverview from './components/FundsOverview';

export default function ClientOverview() {
  const location = useLocation();
  const history = useHistory();

  const bgButton = useColorModeValue('#47abff', '#47ABFF');
  const bgHover = useColorModeValue({ bg: 'blue.400' }, { bg: 'blue.400' });
  const buttonTextColor = useColorModeValue('#ECEFF1', '#162744');
  const borderColor = useColorModeValue('#162744', '#ECEFF1');

  const [piechartData, setPiechartData] = useState(defaultPiechartData);
  const [productsOverview, setProductsOverview] = useState([]);
  const [currentProduct, setCurrentProduct] = useState({
    assetValueHistory: [0],
    breakdownPiechart: [],
    pnlHistory: [],
    product: {},
    roiHistory: [0],
    xirrHistory: [0]
  });
  const [currentProductIndex, setCurrentProductIndex] = useState(0);
  const [productFunds, setProductFunds] = useState([]);
  const [products, setProducts] = useState([]);
  const [transactionType, setTransactionType] = useState(1);
  const [lastProduct, setLastProduct] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [deleteInx, setDeleteInx] = useState(0);
  const [showWelcomeBonus, setShowWelcomeBonus] = useState(true);
  const [total_investment, setTotalInvestment] = useState([]);

  const componentRef = useRef(null);

  const print = useReactToPrint({
    pageStyle: `@media print {
      @page {
        size: 300mm 300mm;
        margin: 0;
      }
    }`,
    contentRef: componentRef
  });

  const toastSuccess = (message) => toast.success(message);
  const toastError = (message) => toast.error(message);

  const {
    isOpen: isAddProductOpen,
    onOpen: onAddProductOpen,
    onClose: onAddProductClose
  } = useDisclosure();

  const {
    isOpen: isModifyClientOpen,
    onOpen: onModifyClientOpen,
    onClose: onModifyClientClose
  } = useDisclosure();

  const {
    isOpen: isStartTransactionOpen,
    onOpen: onStartTransactionOpen,
    onClose: onStartTransactionClose
  } = useDisclosure();

  const {
    isOpen: isTransactionOpen,
    onOpen: onTransactionOpen,
    onClose: onTransactionClose
  } = useDisclosure();

  const {
    isOpen: isEditProductOpen,
    onOpen: onEditProductOpen,
    onClose: onEditProductClose
  } = useDisclosure();

  const {
    isOpen: isTrasactionAuditOpen,
    onOpen: onTransactionAuditOpen,
    onClose: onTransactionAuditClose
  } = useDisclosure();

  const {
    isOpen: isDeleteProductOpen,
    onOpen: onDeleteProductOpen,
    onClose: onDeleteProductClose
  } = useDisclosure();

  const selectTransaction = (id) => {
    setTransactionType(id);
    onTransactionOpen();
  };

  const fetchTransactions = async () => {
    try {
      if (currentProduct.product['clientId'] && currentProduct.product['ID']) {
        const transactions = await getTransactions(
          currentProduct.product['clientId'],
          currentProduct.product['ID']
        );
        setTransactions(transactions);
      }
    } catch (error) {
      console.error('Error fetching transactions:', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (currentProduct.product['clientId'] && currentProduct.product['ID']) {
      fetchTransactions();
    }
  }, [currentProduct.product]);

  useEffect(() => {
    if (transactions.length > 0 && currentProduct.product) {
      handleCurrentProductHelper(currentProduct);
    }
  }, [transactions, currentProduct]);

  const handleCurrentProductHelper = async (ps) => {
    if (transactions.length === 0) {
      return;
    }

    setCurrentProduct(ps);
    const funds = Object.keys(ps.product.balances);
    const amount = Object.values(ps.product.balances);
    const newProductFunds = [];
    const startDates = getFundsStartDate(transactions);

    for (let i = 0; i < funds.length; i++) {
      if (amount[i] < 0.01) {
        continue;
      }

      let roiFund = 0;

      const transactionsForFund = transactionsFund(getFundCode(funds[i]), transactions);

      const totalInvestmentAmount = getTotalInvestmentAmountByFund(transactionsForFund);
      const totalInvestmentValue = getTotalInvestmentValueByFund(transactionsForFund);

      roiFund = getRoiFund(totalInvestmentAmount, totalInvestmentValue);

      const fundCode = parseInt(getFundCode(funds[i]), 10);
      const date = startDates[fundCode];
      const averagePrice = await getFundsAveragePrice(String(funds[i]));

      newProductFunds.push({
        no: i + 1,
        asset: formatFundName(funds[i]),
        start_date: convertDateToRender(date),
        units: amount[i].toFixed(2),
        value: ps.breakdownPiechart[funds[i]].toFixed(2),
        current_price: (ps.breakdownPiechart[funds[i]] / amount[i]).toFixed(3),
        id: funds[i],
        roi: roiFund.toFixed(2),
        average_price: averagePrice.averageBidPrice.toFixed(2)
      });
    }

    setProductFunds(newProductFunds);

    const colors = generateColors(Object.keys(ps.breakdownPiechart).length);
    const filtered = Object.keys(ps.breakdownPiechart).reduce((filtered, key) => {
      filtered[key] = ps.breakdownPiechart[key];
      return filtered;
    }, {});

    const byNumberDesc = (a, b) => parseFloat(filtered[b]) - parseFloat(filtered[a]);
    const labels = Object.keys(filtered)
      .sort(byNumberDesc)
      .map((e) => formatFundName(e));

    const options = fillPiechartData(labels, colors);
    const newPiechart = {
      ...piechartData,
      data: Object.values(filtered).sort((a, b) => b - a),
      options: options
    };
    setPiechartData(newPiechart);
    setIsLoading(false);
  };

  const handleCurrentProduct = async (p, index) => {
    try {
      const ps = await getProductWithStats(p);
      setCurrentProduct(ps);
      setCurrentProductIndex(index);
      localStorage.setItem('currentProductId', p.toString());

      await handleCurrentProductHelper(ps);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      toastError(err);
    }
  };

  useEffect(() => {
    const storedProductId = localStorage.getItem('currentProductId');
    if (products.length > 0) {
      if (storedProductId) {
        const productIndex = products.findIndex(
          (product) => product.ID.toString() === storedProductId
        );
        if (productIndex !== -1) {
          handleCurrentProduct(products[productIndex].ID, productIndex);
        } else {
          handleCurrentProduct(products[0].ID, 0);
        }
      } else {
        handleCurrentProduct(products[0].ID, 0);
      }
    }
  }, [products]);

  const onRefresh = () => {
    window.location.reload(true);
  };

  const handleDeleteProduct = async (id) => {
    if (products.length === 1) {
      setLastProduct(products[0].clientId);
      setDeleteInx(id);
      onDeleteProductOpen();
    } else {
      try {
        const success = await deleteProduct(id);

        if (success) {
          if (lastProduct) {
            const success2 = await removeClient(lastProduct);

            if (success2) {
              toastSuccess('Product deleted successfully.');
              toastSuccess('Client deleted successfully.');
              history.push('/agent/clients');
            } else {
              toastError('Failed to delete client.');
            }
          } else {
            toastSuccess('Product deleted successfully.');
            window.location.reload(true);
          }
        } else {
          toastError('Failed to delete product.');
        }
      } catch (error) {
        toastError('An error occurred while deleting the product.');
      }
    }
  };

  useEffect(() => {
    const applyProducts = (arr) => {
      const res = [];
      for (let i = 0; i < arr.length; i++) {
        setTotalInvestment((prev) => [
          ...prev,
          {
            ID: arr[i].product['ID'],
            assetValue: arr[i].assetValueHistory[0]
          }
        ]);
        res.push({
          total_investment: arr[i].product.totalInvested.toFixed(2),
          aum: arr[i].assetValueHistory[0].toFixed(2),
          unrealised_pl: arr[i].pnlHistory[0].toFixed(2),
          xirr: arr[i].xirrHistory[0].toFixed(2),
          roi: arr[i].roiHistory[0].toFixed(2),
          welcome_bonus: showWelcomeBonus,
          totalInvestedWithoutBonus: arr[i].totalInvestedWithoutBonus.toFixed(2)
        });
      }
      return res;
    };

    const fetchProducts = async () => {
      let i = location.pathname.replace('/agent/client/', '');
      try {
        setIsLoading(true);
        let x = parseInt(i);
        const p = await getClientProducts(x, 100, 0);
        setProducts(p);
        const pss = await Promise.all(
          p.map(async (e) => {
            const s = await getProductWithStats(e.ID);
            return s;
          })
        );
        setProductsOverview(applyProducts(pss));
      } catch (err) {
        console.log(err);
      }
    };

    fetchProducts();
  }, [location.pathname]);

  useEffect(() => {
    const updateProductsOverview = () => {
      const updatedOverview = products.map((product, index) => {
        if (!productsOverview[index]) {
          return productsOverview[index];
        }

        const totalInvested = products[index].totalInvested;
        const totalInvestedWithoutBonus = parseFloat(totalInvested + products[index].bonus);

        const updatedTotalInvestment = showWelcomeBonus ? totalInvestedWithoutBonus : totalInvested;

        return {
          ...productsOverview[index],
          total_investment: updatedTotalInvestment.toFixed(2),
          welcome_bonus: showWelcomeBonus
        };
      });

      setProductsOverview(updatedOverview);
    };

    if (products.length > 0) {
      updateProductsOverview();
    }
  }, [showWelcomeBonus, products]);

  const cleanData = (data) => {
    data.assetValueHistory = data.assetValueHistory.map((f) => limitDpTo(f, 2));
    data.pnlHistory = data.pnlHistory.map((f) => limitDpTo(f, 2));
    data.roiHistory = data.roiHistory.map((f) => limitDpTo(f, 2));
    data.xirrHistory = data.xirrHistory.map((f) => limitDpTo(f, 2));
    return data;
  };

  function transactionsFund(fund, transactions) {
    if (fund.length < 4) {
      return transactions.filter((transaction) => {
        return transaction.fundCode === Number(fund);
      });
    } else {
      return transactions.filter((transaction) => {
        return (
          transaction.fundCode ===
          parseFloat(getFundCodeFromName(fund.asset.split('-').slice(1).join('-').trim()))
        );
      });
    }
  }

  function getFundsStartDate(transactions) {
    return transactions.reduce((acc, transaction) => {
      if (
        transaction.applicationType === 'Net Investment Premium' ||
        transaction.applicationType === 'Switch In'
      ) {
        const { fundCode, runDate } = transaction;

        const date = new Date(runDate);

        if (!acc[fundCode] || date < new Date(acc[fundCode])) {
          acc[fundCode] = runDate;
        }
      }
      return acc;
    }, {});
  }

  function formatData(transactionsFund) {
    return transactionsFund.map((transaction) => {
      return {
        ...transaction,
        runDate: convertDateToRender(transaction.runDate),
        priceEffectiveDate: convertDateToRender(transaction.priceEffectiveDate)
      };
    });
  }

  function getTotalInvestmentAmountByFund(transactions) {
    let totalInvestmentAmount =
      transactions.reduce((acc, d) => {
        if (
          d.applicationType === 'Net Investment Premium' ||
          (d.applicationType === 'Welcome Bonus' && showWelcomeBonus) ||
          d.applicationType === 'Switch In' ||
          d.applicationType === 'Premium Top-up'
        ) {
          return acc + Number(d.balanceUnits);
        } else if (d.applicationType === 'Switch Out' || d.applicationType === 'ILP Withdrawal') {
          return acc - Number(d.amount);
        }
        return acc;
      }, 0) || 1;

    return totalInvestmentAmount;
  }

  function getTotalInvestmentValueByFund(transactions) {
    let totalInvestmentValue = transactions.reduce((acc, d) => {
      return acc + Number(d.price) * Number(d.units);
    }, 0);

    return totalInvestmentValue;
  }

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }} ref={componentRef}>
      <Flex width="100%" margin="20px 0" gap="20px">
        <Button
          color={buttonTextColor}
          fontSize="16px"
          bg={bgButton}
          _hover={bgHover}
          onClick={onModifyClientOpen}
          shadow={'none'}
        >
          Modify Client Details
        </Button>
        <Button
          color={buttonTextColor}
          fontSize="16px"
          bg={bgButton}
          _hover={bgHover}
          onClick={() => print()}
          shadow={'none'}
        >
          Print Info
        </Button>
        <ModifyClientDetailsModal isOpen={isModifyClientOpen} onClose={onModifyClientClose} />
      </Flex>
      {isLoading ? (
        <Flex justify="center" align="center" height="100vh">
          <Spinner size="xl" thickness="4px" speed="0.65s" emptyColor="gray.200" color={bgButton} />
        </Flex>
      ) : (
        <>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 3, '2xl': 5 }} gap="20px" mb="20px">
            {products.map((value, index) => {
              return (
                <Card
                  key={value.ID}
                  border={`2px solid ${index === currentProductIndex ? bgButton : borderColor}`}
                >
                  <Flex direction="row" justify="space-between" align="center">
                    <MiniStatistics
                      p="0px"
                      startContent={
                        <IconBox
                          w="56px"
                          h="56px"
                          bg={bgButton}
                          _hover={bgHover}
                          title="Product Statistics"
                          icon={
                            <Icon
                              w="32px"
                              h="32px"
                              as={MdBarChart}
                              color={buttonTextColor}
                              onClick={() => handleCurrentProduct(value.ID, index)}
                              cursor="pointer"
                            />
                          }
                        />
                      }
                      name="Product ID"
                      value={value.name}
                    />
                    <Flex direction="row" gap="10px">
                      <Button
                        bgColor="transparent"
                        _hover="transparent"
                        _active="transparent"
                        px="0"
                        onClick={onEditProductOpen}
                        title="Edit Product and Client info"
                        disabled={Object.keys(currentProduct.product).length === 0}
                      >
                        <IconBox
                          w="39px"
                          h="39px"
                          bg={bgButton}
                          _hover={bgHover}
                          icon={<Icon w="26px" h="26px" as={MdEdit} color={buttonTextColor} />}
                        />
                      </Button>
                      <Button
                        bgColor="transparent"
                        _hover="transparent"
                        _active="transparent"
                        px="0"
                        onClick={() => handleDeleteProduct(value.ID)}
                        title="Delete Product"
                      >
                        <IconBox
                          w="39px"
                          h="39px"
                          bg={bgButton}
                          _hover={bgHover}
                          icon={<Icon w="26px" h="26px" as={MdDelete} color={buttonTextColor} />}
                        />
                      </Button>
                    </Flex>
                  </Flex>
                </Card>
              );
            })}
            <Box py="24px">
              <Button
                bgColor="transparent"
                _hover="transparent"
                _active="transparent"
                onClick={onAddProductOpen}
                px="0"
              >
                <IconBox
                  w="56px"
                  h="56px"
                  bg={bgButton}
                  _hover={bgHover}
                  icon={<Icon w="32px" h="32px" as={MdAddCircle} color={buttonTextColor} />}
                />
              </Button>
              <AddProductModal
                isOpen={isAddProductOpen}
                onClose={onAddProductClose}
                infoToAddNewProduct={currentProduct.product}
              />
            </Box>
          </SimpleGrid>

          <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
            <ProductOverview
              productsOverview={productsOverview}
              setShowWelcomeBonus={setShowWelcomeBonus}
            />
          </SimpleGrid>
          <SimpleGrid columns={1} gap="20px" mb="20px">
            <SummaryPieCard
              piechartData={piechartData.data}
              piechartOptions={piechartData.options}
              vertical={false}
              hasDetails={true}
            />
          </SimpleGrid>
          <SimpleGrid columns={1} gap="20px" mb="20px">
            <PriceChanges
              currentProduct={cleanData(currentProduct)}
              transactions={transactions}
              infoToAddNewProduct={currentProduct.product}
            />
          </SimpleGrid>
          {/* <SimpleGrid columns={1} gap="20px" mb="20px">
            <TransactionBarChart transactions={transactions} />
          </SimpleGrid>
           */}
          <SimpleGrid columns={1} gap="20px" mb="20px">
            <FundsOverview
              fundsData={productFunds}
              transactions={transactions}
              showWelcomeBonus={showWelcomeBonus}
              totalInvestmentValue={total_investment}
              currentProduct={currentProduct.product}
            />
          </SimpleGrid>
          <SimpleGrid columns={1} gap="20px" mb="20px">
            {productFunds.map((fund, index) => {
              const formattedData = formatData(transactionsFund(fund, transactions));

              if (transactionsFund.length !== 0) {
                const totalInvestmentValue = getTotalInvestmentValueByFund(formattedData);
                const totalInvestmentAmount = getTotalInvestmentAmountByFund(formattedData);
                const roi = getRoiFund(totalInvestmentAmount, totalInvestmentValue);

                return (
                  <BasicTableWithPagination
                    key={index}
                    columnsData={columnsDataTransactions}
                    tableData={formattedData}
                    title={fund.asset}
                    totalInvestmentValueByFund={totalInvestmentValue}
                    totalInvestmentAmountByFund={totalInvestmentAmount}
                    roiByFund={roi}
                    index={index}
                    showWelcomeBonus={showWelcomeBonus}
                  />
                );
              }
            })}
          </SimpleGrid>
          <Flex px="25px" justify="center" width="100%" margin="20px 0" gap="10px">
            <Button
              color={buttonTextColor}
              fontSize="16px"
              bg={bgButton}
              _hover={bgHover}
              onClick={onStartTransactionOpen}
            >
              Create Transaction
            </Button>
            <Button
              color={buttonTextColor}
              fontSize="16px"
              bg={bgButton}
              _hover={bgHover}
              onClick={onTransactionAuditOpen}
            >
              View Audit History
            </Button>

            <StartTransactionModal
              isOpen={isStartTransactionOpen}
              onClose={onStartTransactionClose}
              selectTransaction={selectTransaction}
            />

            <TransactionAuditModal
              productId={currentProduct.product['ID']}
              clientId={currentProduct.product['clientId']}
              isOpen={isTrasactionAuditOpen}
              onClose={onTransactionAuditClose}
              transactions={transactions}
              isLoading={isLoading}
            />
          </Flex>
          <TransactionModal
            isOpen={isTransactionOpen}
            onClose={onTransactionClose}
            transactionType={transactionType}
            columnsData={columnsDataProducts}
            tableData={productFunds}
            piechartData={piechartData.data}
            piechartOptions={piechartData.options}
            product={currentProduct.product}
            onRefresh={onRefresh}
          />
          <EditProductModal
            isOpen={isEditProductOpen}
            onClose={onEditProductClose}
            borderColor={borderColor}
            productInfo={currentProduct.product}
          />
          <ConfirmModal
            isOpen={isDeleteProductOpen}
            onClose={onDeleteProductClose}
            message={
              'This is the last product. Deleting it will remove the client as well. Are you sure?'
            }
            buttonText={'Cancel'}
            onSubmit={async () => {
              try {
                const success = await deleteProduct(deleteInx);

                if (success) {
                  if (lastProduct) {
                    const success2 = await removeClient(lastProduct);

                    if (success2) {
                      toastSuccess('Product deleted successfully.');
                      toastSuccess('Client deleted successfully.');
                      history.push('/agent/clients');
                    } else {
                      toastError('Failed to delete client.');
                    }
                  } else {
                    toastSuccess('Product deleted successfully.');
                    window.location.reload(true);
                  }
                } else {
                  toastError('Failed to delete product.');
                }
              } catch (error) {
                toastError('An error occurred while deleting the product.');
              }
              onDeleteProductClose();
            }}
          />
        </>
      )}
    </Box>
  );
}
const EditProductModal = ({ isOpen, onClose, productInfo, borderColor }) => {
  const bgColor = useColorModeValue('#ECEFF1', '#162744');

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent w="95%" maxWidth="95%" border={`1px solid ${borderColor}`} bg={bgColor}>
        <ModalHeader fontSize="24px" fontWeight="400">
          Edit Product and Client info
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ImportClientForm productInfo={productInfo} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
