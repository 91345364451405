/* eslint-disable */

import React from 'react';
// Custom components
import DefaultAuth from 'layouts/auth/Default';
// Assets
import illustration from 'assets/img/auth/auth.png';
import { forget, validateOTP, changePassword } from 'service/api';
import ForgetPasswordFormPanel from 'views/auth/forgetPassword/ForgetPasswordFormPanel';
import toast from 'react-hot-toast';

function ForgetPassword() {
  const [step, setStep] = React.useState(0);
  const [email, setEmail] = React.useState('');
  const [otp, setOTP] = React.useState('');
  const [password, setPassword] = React.useState('');

  const toastSuccess = (message) => toast.success(message);
  const toastError = (message) => toast.error(message);

  React.useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const queryEmail = queryParams.get('email');
    if (queryEmail !== null) {
      setEmail(queryEmail);
      setStep(1);
    }
  }, [email, setEmail]);

  // Step 1: Send OTP to user
  const handleEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleForget = async () => {
    try {
      await forget(email);
      setStep(step + 1);
      toastSuccess('Please check your email for the OTP to verify yourself.');
    } catch (err) {
      toastError(err);
    }
  };

  // Step 2: Verify OTP of user
  const handleOTP = (event) => {
    setOTP(event.target.value);
  };

  const handleValidateOTP = async () => {
    try {
      await validateOTP(email, otp);
      setStep(step + 1);
      toastSuccess('OTP validated. Please enter your new password.');
    } catch (err) {
      toastError(err);
    }
  };

  // Step 3: Change password if OTP of user is verified
  const handlePassword = (event) => {
    setPassword(event.target.value);
  };

  const handleChangePassword = async () => {
    try {
      await changePassword(password);
      toastSuccess('Your password is updated.');
      reset();
      const signInPage = `${process.env.PUBLIC_URL}/auth/sign-in`;
      window.location.replace(signInPage);
    } catch (err) {
      toastError(err);
    }
  };

  const reset = () => {
    setStep(0);
    setPassword('');
    setOTP('');
    setEmail('');
  };

  const props = [
    {
      stepHeader: 'Forget Password',
      subHeader: 'Enter your email to receive the OTP to reset password',
      inputType: 'email',
      inputPlaceholder: 'sample@email.com',
      inputPrompt: 'Email',
      userInput: email,
      changeUserInput: handleEmail,
      confirmUserInput: handleForget
    },
    {
      stepHeader: 'Forget Password',
      subHeader: 'Enter your OTP to verify yourself',
      inputType: 'otp',
      inputPlaceholder: '12345',
      inputPrompt: 'One-Time Password',
      userInput: otp,
      changeUserInput: handleOTP,
      confirmUserInput: handleValidateOTP
    },
    {
      stepHeader: 'Forget Password',
      subHeader: 'Enter your new password',
      inputType: 'password',
      inputPlaceholder: 'password',
      inputPrompt: 'Password',
      userInput: password,
      changeUserInput: handlePassword,
      confirmUserInput: handleChangePassword
    }
  ];

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <ForgetPasswordFormPanel {...props[step]} />
    </DefaultAuth>
  );
}

export default ForgetPassword;
