// Chakra imports
import {
  Box,
  Button,
  Flex,
  Icon,
  SimpleGrid,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';
import { MdDelete } from 'react-icons/md';

import BasicTableWithPagination from 'components/table/BasicTableWithPagination';
import { useEffect, useState } from 'react';
import tableClientAlerts from 'views/agent/alerts/variables/tableClientAlerts.json';
import AlertsModal from 'views/agent/clientOverview/components/AlertsModal';
import { columnsAlert, columnsCurrentAlert } from './variables/columnsData';

import { formatFundName, getSelfAgentId, getTriggeredAgentAlerts } from 'service/api';

import ConfirmModal from 'components/confirmModal/ConfirmModal';
import { getAgentAlerts, removeAlert } from 'service/api';
import { numberWithCommas } from 'utils/numbers';
import { formatDateStrict } from 'utils/string';

export default function Alerts() {
  // const boxBg = useColorModeValue('#5CE1E6', '#5CE1E6');
  const bgHover = useColorModeValue({ bg: 'blue.400' }, { bg: 'blue.400' });
  const bgButton = useColorModeValue('#47abff', '#47ABFF');
  const buttonTextColor = useColorModeValue('#ECEFF1', '#162744');

  const [alerts, setAlerts] = useState(tableClientAlerts);
  const [currentAlerts, setCurrentAlerts] = useState([]);
  const [deleteIdx, setDeleteIdx] = useState(0);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();

  useEffect(() => {
    const fetchData = async () => {
      const applyAlerts = (arr) => {
        const res = [];
        for (let i = 0; i < arr.length; i++) {
          res.push({
            id: i + 1,
            alert_id: arr[i]['ID'],
            name: formatFundName(arr[i]['fundId']),
            price: numberWithCommas(arr[i]['price']),
            date: formatDateStrict(arr[i]['CreatedAt']),
            triggered_date: formatDateStrict(arr[i]['triggeredAt']),
            delete: (
              <Button
                p="0"
                bg="transparent"
                onClick={() => {
                  onOpen();
                  setDeleteIdx(arr[i]['ID']);
                }}
              >
                <Icon as={MdDelete} h="20px" w="20px" />
              </Button>
            )
          });
        }
        return res;
      };

      const a = await getTriggeredAgentAlerts(getSelfAgentId(), 100, 0);
      const b = await getAgentAlerts(getSelfAgentId(), 100, 0);
      setAlerts(applyAlerts(a));
      setCurrentAlerts(applyAlerts(b));
    };

    fetchData();
  }, []);

  const handleDeleteAlert = async () => {
    try {
      await removeAlert(parseInt(deleteIdx));
      window.location.reload(true);
    } catch (err) {
      alert(err);
    }
  };

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
        {/* <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px"> */}
        <BasicTableWithPagination
          columnsData={columnsAlert}
          tableData={alerts}
          title="Triggered Alerts"
        />
        {/* </SimpleGrid> */}
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
        {/* <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px"> */}
        <BasicTableWithPagination
          columnsData={columnsCurrentAlert}
          tableData={currentAlerts}
          title="Current Alerts"
        />
        {/* </SimpleGrid> */}
      </SimpleGrid>
      <Flex px="25px" justify="center" width="100%" margin="20px 0">
        <Button
          color={buttonTextColor}
          fontSize="16px"
          bg={bgButton}
          mr="16px"
          _hover={bgHover}
          onClick={onAlertOpen}
          shadow="none"
        >
          Configure Alerts
        </Button>
        <AlertsModal isOpen={isAlertOpen} onClose={onAlertClose} />
      </Flex>
      <ConfirmModal
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={handleDeleteAlert}
        message="Your selected alert will be deleted after clicking confirm!"
        buttonText={'Continue editing'}
      />
    </Box>
  );
}
