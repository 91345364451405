import { Box, Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react';
import { css } from '@emotion/react';
import Card from 'components/card/Card.js';
import LineChart from 'components/charts/LineChart';
import { useEffect, useState } from 'react';
import { RiArrowDownSFill, RiArrowUpSFill } from 'react-icons/ri';
import { numberWithCommas } from 'utils/numbers';

export default function TotalSpent(props) {
  const { transactions, currentProduct, ...rest } = props;

  const textColor = useColorModeValue('#162744', '#ECEFF1');
  const borderColor = useColorModeValue('#162744', '#ECEFF1');

  const [lineChartOptions, setLineChartOptions] = useState({});
  const [capital, setCapital] = useState([]);
  const [assetValue, setAssetValue] = useState([]);
  const [currentValue, setCurrentValue] = useState(0);

  function getStartMonth(transactions) {
    const netInvestmentTransaction = transactions
      .filter((t) => t.applicationType === 'Net Investment Premium')
      .sort((a, b) => new Date(a.priceEffectiveDate) - new Date(b.priceEffectiveDate));

    if (netInvestmentTransaction.length === 0) {
      return null;
    }

    return new Date(netInvestmentTransaction[0].priceEffectiveDate);
  }
  function getMonthsBetween(startDate, endDate) {
    const months = [];
    const date = new Date(startDate.getFullYear(), startDate.getMonth(), 1);

    while (date <= endDate) {
      const month = date
        .toLocaleString('default', { year: '2-digit', month: '2-digit' })
        .replace('/', '/');
      months.push(month);
      date.setMonth(date.getMonth() + 1);
    }

    return months;
  }

  const totalInvestmentAmountByMonth = transactions.reduce((acc, curr) => {
    const month = new Date(curr.priceEffectiveDate).toLocaleString('default', {
      month: 'long',
      year: 'numeric'
    });

    if (!acc[month]) {
      acc[month] = 0;
    }

    acc[month] +=
      curr.applicationType === 'Net Investment Premium' || curr.applicationType === 'Welcome bonus'
        ? curr.balanceUnits
        : 0;

    return acc;
  }, {});

  const entriesAmount = Object.entries(totalInvestmentAmountByMonth);

  const sortedEntriesAmount = entriesAmount.sort(([aMonth], [bMonth]) => {
    const dateA = new Date(aMonth + ' 1');
    const dateB = new Date(bMonth + ' 1');

    return dateA - dateB;
  });

  let cumulativeSum = 0;

  const sortedAmount = sortedEntriesAmount.map(([_, value]) => {
    cumulativeSum += value;

    return cumulativeSum;
  });

  const totalInvestmentValueByMonth = transactions.reduce((acc, curr) => {
    const month = new Date(curr.priceEffectiveDate).toLocaleString('default', {
      month: 'long',
      year: 'numeric'
    });

    if (!acc[month]) {
      acc[month] = {};
    }

    const existingTransaction = acc[month][curr.fundCode];

    if (
      !existingTransaction ||
      new Date(curr.priceEffectiveDate) > new Date(existingTransaction.priceEffectiveDate)
    ) {
      acc[month][curr.fundCode] = curr;
    }

    return acc;
  }, {});

  const totalInvestmentValue = Object.entries(totalInvestmentValueByMonth).reduce(
    (acc, [month, funds]) => {
      acc[month] = Object.values(funds).reduce((sum, transaction) => {
        return sum + transaction.balanceUnits * transaction.price;
      }, 0);
      return acc;
    },
    {}
  );

  const entriesValue = Object.entries(totalInvestmentValue);

  const sortedEntriesValue = entriesValue
    .sort(([aMonth], [bMonth]) => {
      const dateA = new Date(aMonth + ' 1');
      const dateB = new Date(bMonth + ' 1');

      return dateA - dateB;
    })
    .map((el) => +el[1].toFixed(2));

  useEffect(() => {
    const startDate = getStartMonth(transactions);

    if (!startDate) return;

    const endDate = new Date(Math.max(...transactions.map((t) => new Date(t.priceEffectiveDate))));

    const categories = getMonthsBetween(startDate, endDate);

    setCurrentValue(currentProduct.assetValueHistory[0]);
    setCapital(sortedAmount);
    setAssetValue(sortedEntriesValue);

    const maxAmount = Math.max(...sortedAmount);
    const maxValue = Math.max(...sortedEntriesValue);
    const maxYValue = Math.max(maxAmount, maxValue) + 10000;

    setLineChartOptions({
      chart: {
        toolbar: { show: false }
      },
      colors: ['#4318FF', '#39B8FF'],
      markers: {
        size: 0,
        hover: {
          size: 4,
          colors: ['#4318FF', '#39B8FF'],
          strokeWidth: 2,
          strokeColors: '#fff'
        }
      },
      tooltip: { theme: 'dark', style: { color: textColor } },
      dataLabels: { enabled: false },
      stroke: {
        curve: 'smooth',
        type: 'line',
        width: 2
      },
      xaxis: {
        type: 'numeric',
        categories,
        labels: {
          style: { colors: '#adadad', fontSize: '12px', fontWeight: '500' }
        },
        axisBorder: { show: false },
        axisTicks: { show: false }
      },
      yaxis: {
        show: true,
        type: 'numeric',
        min: 0,
        max: maxYValue,
        labels: {
          style: { colors: 'transparent', fontSize: '12px', fontWeight: '500' }
        }
      },
      legend: { show: false },
      grid: {
        show: false,
        column: { color: ['#7551FF', '#39B8FF'], opacity: 0.5 }
      },
      color: ['#7551FF', '#39B8FF']
    });
  }, [currentProduct, transactions]);

  const xirrVal = currentProduct.xirrHistory[0];
  const roiVal = currentProduct.roiHistory[0];

  return (
    <Card
      justifyContent="center"
      align="center"
      direction="column"
      w="100%"
      h="100%"
      mb="0px"
      border={`1px solid ${borderColor}`}
      {...rest}
      pb="15px"
    >
      <Flex justify="space-between" ps="0px">
        <Flex align="center" w="100%">
          <Text color={textColor} fontSize="22px" fontWeight="600">
            Portfolio Performance
          </Text>
        </Flex>
      </Flex>
      <Flex w="100%" flexDirection={{ base: 'column', lg: 'row' }}>
        <Flex flexDirection="column" me="20px" mt="28px">
          <Text
            color={textColor}
            fontSize="30px"
            textAlign="start"
            fontWeight="700"
            lineHeight="100%"
          >
            ${numberWithCommas(currentValue.toFixed(2))}
          </Text>
          <Flex align="center" mt="8px">
            <Text color="secondaryGray.600" fontSize="sm" fontWeight="500" mt="4px" me="12px">
              XIRR
            </Text>
            <Flex align="center">
              {xirrVal >= 0 ? (
                <Icon as={RiArrowUpSFill} color="green.500" me="2px" mt="2px" />
              ) : (
                <Icon as={RiArrowDownSFill} color="red.500" me="2px" mt="2px" />
              )}
              <Text color={xirrVal >= 0 ? 'green.500' : 'red.500'} fontSize="sm" fontWeight="700">
                {xirrVal.toFixed(2)}%
              </Text>
            </Flex>
          </Flex>
          <Flex align="center" mt="8px" mb="20px">
            <Text color="secondaryGray.600" fontSize="sm" fontWeight="500" mt="4px" me="12px">
              ROI
            </Text>
            <Flex align="center">
              {roiVal >= 0 ? (
                <Icon as={RiArrowUpSFill} color="green.500" me="2px" mt="2px" />
              ) : (
                <Icon as={RiArrowDownSFill} color="red.500" me="2px" mt="2px" />
              )}
              <Text color={roiVal >= 0 ? 'green.500' : 'red.500'} fontSize="sm" fontWeight="700">
                {roiVal.toFixed(2)}%
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Box
          w="95%"
          maxW="95%"
          css={css({ '@media print': { maxWidth: '60%', minHeight: '360px' } })}
          minH="260px"
          mt="auto"
        >
          <LineChart
            chartData={[
              { name: 'Asset value', data: assetValue },
              { name: 'Capital', data: capital }
            ]}
            chartOptions={lineChartOptions}
          />
        </Box>
      </Flex>
    </Card>
  );
}
