import React from 'react';
import ReactDOM from 'react-dom';
import 'assets/css/App.css';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import AuthLayout from 'layouts/auth';
import AgentLayout from 'layouts/agent';
import AdminLayout from 'layouts/admin';
import { ChakraProvider } from '@chakra-ui/react';
import theme from 'theme/theme';
import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui';
import { hasTokens, hasAdminTokens } from 'service/token';

import { history } from 'service/history';

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        hasTokens() ? <Component {...props} /> : <Redirect to={{ pathname: '/auth/sign-in' }} />
      }
    />
  );
};

const PrivateAdminRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        hasAdminTokens() ? <Component {...props} /> : <Redirect to={{ pathname: '/auth/sign-in' }} />
      }
    />
  );
};

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      <ThemeEditorProvider>
        <BrowserRouter hist={history}>
          <Switch>
            <Route path={`/auth`} component={AuthLayout} />
            <PrivateRoute path={`/agent`} component={AgentLayout} />
            <PrivateAdminRoute path={`/admin`} component={AdminLayout} />
            <Redirect from="/" to="/auth/sign-in" />
          </Switch>
        </BrowserRouter>
      </ThemeEditorProvider>
    </React.StrictMode>
  </ChakraProvider>,
  document.getElementById('root')
);
