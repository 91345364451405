import { Box, Switch, Text, useColorModeValue } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useTable } from 'react-table';

const ProductOverview = ({ setShowWelcomeBonus, productsOverview }) => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    try {
      if (productsOverview && Array.isArray(productsOverview)) {
        setTableData(productsOverview);
      } else {
        setTableData([]);
      }
    } catch (error) {
      console.log(error, 'ERROR');
    }
  }, [productsOverview]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'TOTAL INVESTMENT',
        accessor: 'total_investment'
      },
      {
        Header: 'AUM',
        accessor: 'aum'
      },
      {
        Header: 'UNREALISED PROFIT/LOSS',
        accessor: 'unrealised_pl'
      },
      {
        Header: 'XIRR',
        accessor: 'xirr'
      },
      {
        Header: 'ROI',
        accessor: 'roi'
      },
      {
        Header: 'WELCOME BONUS',
        accessor: 'welcome_bonus',
        Cell: ({ row }) => (
          <Switch
            isChecked={row.original.welcome_bonus}
            onChange={(e) => handleCheckboxChange(e, row.index)}
            sx={{
              transition: 'all 0.2s ease-in-out'
            }}
          />
        )
      }
    ],
    [tableData]
  );

  const handleCheckboxChange = (e, rowIndex) => {
    const updatedData = [...tableData];
    updatedData[rowIndex].welcome_bonus = e.target.checked;
    setTableData(updatedData);
    setShowWelcomeBonus(e.target.checked);
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    data: tableData || [],
    columns,
    debug: true,
    manualSubRowExpansion: true
  });

  const borderColorCard = useColorModeValue('#162744', '#ECEFF1');

  return (
    <Box w="100%" py="20px" borderRadius="20px" border={`1px solid ${borderColorCard}`}>
      <Text fontSize="22px" fontWeight="700" lineHeight="100%" mb="5px" ml="20px">
        Product Overview
      </Text>
      <table {...getTableProps()} style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr key={index} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  key={column.id}
                  {...column.getHeaderProps()}
                  style={{
                    padding: '10px',
                    borderBottom: '1px solid #A0AEBF',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: '13px'
                  }}
                >
                  <Text color="#A0AEBF">{column.render('Header')}</Text>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows?.map((row, index) => {
            prepareRow(row);
            return (
              <tr key={index} {...row.getRowProps()}>
                {row.cells.map((cell, index) => (
                  <td
                    key={index}
                    {...cell.getCellProps()}
                    style={{
                      padding: '10px',
                      textAlign: 'center',
                      fontSize: '14px',
                      fontWeight: 'bold'
                    }}
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </Box>
  );
};

export default ProductOverview;
