/* eslint-disable no-undef */
// Chakra Imports
import {
  Avatar,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue
} from '@chakra-ui/react';
// Custom Components
import { SidebarResponsive } from 'components/sidebar/Sidebar';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
// Assets
import { FaEthereum } from 'react-icons/fa';
import { MdNotificationsNone } from 'react-icons/md';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import routes from 'routes.js';
import { history } from 'service/history';
import { clearTokens, getName } from 'service/token';

import FixedPlugin from 'components/fixedPlugin/FixedPlugin';
import BasicTableWithPagination from 'components/table/BasicTableWithPagination';
import { formatFundName, getSelfAgentId, getTriggeredAgentAlerts } from 'service/api';
import { numberWithCommas } from 'utils/numbers';
import { formatDateStrict } from 'utils/string';
import { columnsAlert } from 'views/agent/dashboard/variables/columnsData';

export default function HeaderLinks(props) {
  const { type, secondary } = props;
  // Chakra Color Mode
  const navbarIcon = useColorModeValue('#162744', '#ECEFF1');
  let menuBg = useColorModeValue('#ECEFF1', '#162744');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const ethColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('#162744', '#ECEFF1');
  const ethBg = useColorModeValue('secondaryGray.300', 'navy.900');
  const ethBox = useColorModeValue('white', 'navy.800');
  // const shadow = useColorModeValue(
  //   '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
  //   '14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
  // );

  // APIs
  const [alerts, setAlerts] = useState([]);

  const applyAlerts = (arr) => {
    const res = [];
    for (let i = 0; i < arr.length; i++) {
      res.push({
        id: arr[i]['ID'],
        name: formatFundName(arr[i]['fundId']),
        price: numberWithCommas(arr[i]['price'].toFixed(3)),
        date: formatDateStrict(arr[i]['CreatedAt']),
        triggered_date: formatDateStrict(arr[i]['triggeredAt'])
      });
    }
    return res;
  };

  useEffect(() => {
    if (window.location.pathname.includes('agent')) {
      const fetchData = async () => {
        const a = await getTriggeredAgentAlerts(getSelfAgentId(), 100, 0);
        setAlerts(applyAlerts(a));
      };

      fetchData();
    }
  }, []);

  const isAdmin = () => {
    return window.location.pathname.includes('admin');
  };

  const logOut = () => {
    clearTokens();
    if (isAdmin()) {
      history.push('/auth/sign-in-admin');
    } else {
      history.push('/auth/sign-in');
    }
  };

  return (
    <Flex
      w={{ sm: '100%', md: 'auto' }}
      alignItems="center"
      flexDirection="row"
      bg={menuBg}
      flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
      p="10px"
      borderRadius="30px"
      // boxShadow={shadow}
    >
      {/* <SearchBar
        mb={secondary ? { base: "10px", md: "unset" } : "unset"}
        me='10px'
        borderRadius='30px'
      /> */}
      <Flex
        bg={ethBg}
        display={secondary ? 'flex' : 'none'}
        borderRadius="30px"
        ms="auto"
        p="6px"
        align="center"
        me="6px"
      >
        <Flex
          align="center"
          justify="center"
          bg={ethBox}
          h="29px"
          w="29px"
          borderRadius="30px"
          me="7px"
        >
          <Icon color={navbarIcon} w="9px" h="14px" as={FaEthereum} />
        </Flex>
        <Text w="max-content" color={ethColor} fontSize="sm" fontWeight="700" me="6px">
          1,924
          <Text as="span" display={{ base: 'none', md: 'unset' }}>
            {' '}
            ETH
          </Text>
        </Text>
      </Flex>
      <SidebarResponsive routes={routes.filter((route) => route.layout === type)} />
      {/* <Menu>
        <MenuButton p="0px">
          <Icon mt="6px" as={MdNotificationsNone} color={navbarIcon} w="18px" h="18px" me="10px" />
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="20px"
          borderRadius="20px"
          bg={menuBg}
          border="none"
          mt="22px"
          me={{ base: '30px', md: 'unset' }}
          minW={{ base: 'unset', md: '400px', xl: '450px' }}
          maxW={{ base: '360px', md: 'unset' }}
        >
          <Flex jusitfy="space-between" w="100%" mb="20px">
            <Text fontSize="md" fontWeight="600" color={textColor}>
              Notifications
            </Text>
            <Text fontSize="sm" fontWeight="500" color={textColorBrand} ms="auto" cursor="pointer">
              Mark all read
            </Text>
          </Flex>
          <Flex flexDirection="column">
            <MenuItem
              _hover={{ bg: 'none' }}
              _focus={{ bg: 'none' }}
              px="0"
              borderRadius="8px"
              mb="10px"
            >
              <ItemContent
                info="Insurance Changes from Great Eastern"
                aName="Insurance Policy"
                link="https://www.greateasternlife.com/sg/en/personal-insurance.html"
              />
            </MenuItem>
            <MenuItem
              _hover={{ bg: 'none' }}
              _focus={{ bg: 'none' }}
              px="0"
              borderRadius="8px"
              mb="10px"
            >
              <ItemContent
                info="ILP Selling Strategy Changes"
                aName="Investment Linked Policy"
                link="https://www.greateasternlife.com/sg/en/personal-insurance/our-products/wealth-accumulation/great-invest-advantage.html"
              />
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu> */}

      <Menu>
        <MenuButton p="0px">
          {window.location.pathname.includes('agent') ? (
            <Icon
              mt="6px"
              as={MdNotificationsNone}
              color={navbarIcon}
              w="25px"
              h="25px"
              me="10px"
            />
          ) : (
            <></>
          )}
        </MenuButton>
        <MenuList
          p="20px"
          borderRadius="20px"
          bg={menuBg}
          border="none"
          mt="22px"
          me={{ base: '30px', md: 'unset' }}
          minW={{ base: 'unset', md: '400px', xl: '450px' }}
          maxW={{ base: '360px', md: 'unset' }}
        >
          <Flex jusitfy="space-between" w="100%" mb="20px">
            <Text fontSize="md" fontWeight="600" color={textColor}>
              Alerts
            </Text>
          </Flex>
          <Flex flexDirection="column"></Flex>
          <BasicTableWithPagination columnsData={columnsAlert} tableData={alerts} title="" />
        </MenuList>
      </Menu>
      <FixedPlugin width="30px" height="40px" position="static" marginRight="7px" />

      {/* <ThemeEditor navbarIcon={navbarIcon} /> */}

      <Menu>
        <MenuButton p="0px" pr="10px">
          <Avatar
            _hover={{ cursor: 'pointer' }}
            color="white"
            name={getName()}
            bg="#11047A"
            size="sm"
            w="40px"
            h="40px"
          />
        </MenuButton>
        <MenuList
          p="0px"
          mt="10px"
          borderRadius="20px"
          bg={menuBg}
          border={`1px solid ${borderColor}`}
        >
          <Flex w="100%" mb="0px">
            <Text
              ps="20px"
              pt="16px"
              pb="10px"
              w="100%"
              borderBottom="1px solid"
              borderColor={borderColor}
              fontSize="sm"
              fontWeight="700"
              color={textColor}
            >
              👋&nbsp; Hey, {getName()}
            </Text>
          </Flex>
          <Flex flexDirection="column" p="10px">
            <MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} borderRadius="8px" px="14px">
              <NavLink
                fontSize="sm"
                to={`${process.env.PUBLIC_URL}/${isAdmin() ? 'admin' : 'agent'}/profile-settings`}
              >
                Profile Settings
              </NavLink>
            </MenuItem>
            <MenuItem
              _hover={{ bg: 'none' }}
              _focus={{ bg: 'none' }}
              color="red.400"
              borderRadius="8px"
              px="14px"
              onClick={logOut}
            >
              <Text fontSize="sm">Log out</Text>
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  );
}

HeaderLinks.propTypes = {
  type: PropTypes.string,
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func
};
