export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function limitNumber(number) {
  const maxLimit = 999999;
  const minLimit = -999999;

  if (number > maxLimit) {
    return maxLimit;
  } else if (number < minLimit) {
    return minLimit;
  } else {
    return number;
  }
}

export function limitDpTo(number, n) {
  return parseFloat(number.toFixed(n));
}

export function isInteger(n) {
  return /^\d+$/.test(n);
}

export function isFloat(n) {
  return /^-?\d*\.?\d+$/.test(n);
}

export function formatPrice(n) {
  // Client want dp to be 3
  // Note that dp beyond 3 can cause bug in numberWithCommas to print commas for dps
  return numberWithCommas(parseFloat(n).toFixed(3));
}

export const parseNumberOrZero = (value) => {
  return value === '' || isNaN(parseFloat(value)) ? 0.0 : parseFloat(value);
};

export function getRoiFund(totalInvestmentAmount, totalInvestmentValue) {
  let roi =
    totalInvestmentAmount === 0
      ? 1
      : ((totalInvestmentValue - totalInvestmentAmount) / totalInvestmentAmount) * 100;

  return roi;
}
