import { Box, Flex, SimpleGrid } from '@chakra-ui/react';
import Card from 'components/card/Card';
import React, { useState } from 'react';
import { formatFundName } from 'service/api';

export default function FundAllocationCard(props) {
  const { apportionment } = props;

  return (
    <Card direction="column" w="100%" px="0px" py="0px" overflowX={{ sm: 'scroll', lg: 'hidden' }}>
      <Flex direction="column" my="12px" mx="6px">
        <SimpleGrid columns={2} spacing={10} fontWeight="600">
          <Box>Fund</Box>
          <Box>Allocation</Box>
        </SimpleGrid>
        {apportionment.map((value, index) => (
          <SimpleGrid columns={2} spacing={10} key={index} my="4px" fontWeight="500">
            <Flex align="center">{formatFundName(parseInt(value.id))}</Flex>
            <Flex align="center">{(value.pct * 100).toFixed(3)}%</Flex>
          </SimpleGrid>
        ))}
      </Flex>
    </Card>
  );
}
