import { Icon, Text, useColorModeValue } from '@chakra-ui/react';
import 'assets/css/MiniCalendar.css';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
// Chakra imports
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
// Custom components
import Card from 'components/card/Card.js';

export default function MiniCalendar(props) {
  const { selectRange, date, onDateChange, ...rest } = props;

  const borderColor = useColorModeValue('#162744', '#ECEFF1');
  const bgCard = useColorModeValue('#ECEFF1', '#162744');

  return (
    <Card
      align="center"
      direction="column"
      w="100%"
      maxW="max-content"
      p="20px 15px"
      h="max-content"
      border={`1px solid ${borderColor}`}
      {...rest}
      bg={bgCard}
    >
      <div>
        <Calendar
          locale="en-US"
          onChange={onDateChange}
          value={date}
          selectRange={selectRange}
          views={['month', 'year']}
          tileContent={<Text color="brand.500"></Text>}
          prevLabel={<Icon as={MdChevronLeft} w="24px" h="24px" mt="4px" />}
          nextLabel={<Icon as={MdChevronRight} w="24px" h="24px" mt="4px" />}
        />
      </div>
    </Card>
  );
}
