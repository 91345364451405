import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useColorModeValue
} from '@chakra-ui/react';

import { useEffect, useState } from 'react';
import { getFundIdsWithAppendedNullOption } from 'service/api';
import ImportClientProductOnly from './ImportClientProductOnly';

import { ProductInfo } from 'views/agent/createClientProfile/components/AddProduct';

export default function AddProductModal(props) {
  const { isOpen, onClose, infoToAddNewProduct } = props;

  const modalColor = useColorModeValue('#ECEFF1', '#162744');
  const cardColor = useColorModeValue('#ECEFF1', '#162744');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const inputBg = useColorModeValue('whiteAlpha.300', 'whiteAlpha.100');
  const inputText = useColorModeValue('gray.700', 'gray.100');

  const [premium, setPremium] = useState(0);
  const [premiumDate, setPremiumDate] = useState('');
  const [premiumType, setPremiumType] = useState('monthly');
  const [premiumName, setPremiumName] = useState('');
  const [funds, setFunds] = useState([]);
  const [fundOptions, setFundOptions] = useState([]);
  const [policyFeeType, setPolicyFeeType] = useState('op0');
  const [welcomeBonus, setWelcomeBonus] = useState(0);

  const selectAllocation = (index, allocation) => {
    let newFunds = funds;
    newFunds[index].num = allocation;
    setFunds([...newFunds]);
  };

  const selectFund = (index, id) => {
    let newFunds = funds;
    newFunds[index].id = id;
    setFunds([...newFunds]);
  };

  const getFirstUnselectedFundId = () => {
    const ids = funds.map((t) => parseInt(t.id));
    let i = 0;
    for (; i < fundOptions.length; i++) {
      if (!ids.includes(fundOptions[i])) {
        return fundOptions[i];
      }
    }
    return 0;
  };

  const addFund = () => {
    if (funds.length >= fundOptions.length) {
      return;
    }
    let newFund = new ProductInfo(getFirstUnselectedFundId(), 0.0);
    let newFunds = funds.concat(newFund);
    setFunds(newFunds);
  };

  const removeFund = () => {
    if (funds.length <= 1) {
      return;
    }
    funds.pop();
    setFunds([...funds]);
  };

  useEffect(() => {
    const fetchData = async () => {
      const res = await getFundIdsWithAppendedNullOption();
      setFundOptions(res);
      setFunds([new ProductInfo(0, 0.0)]);
    };
    fetchData();
  }, []);

  const handlePremium = (event) => {
    const x = parseFloat(event.target.value);
    if (isNaN(x)) {
      setPremium(0);
    } else {
      setPremium(x);
    }
  };

  const handlePremiumType = (event) => {
    setPremiumType(event.target.value);
  };

  const handlePremiumName = (event) => {
    setPremiumName(event.target.value);
  };

  const handlePremiumDate = (event) => {
    setPremiumDate(event.target.value);
  };

  const handlePolicyFeeType = (event) => {
    setPolicyFeeType(event.target.value);
  };

  const handleWelcomeBonus = (event) => {
    const x = parseFloat(event.target.value);
    if (isNaN(x)) {
      setWelcomeBonus(0);
    } else {
      setWelcomeBonus(x);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW="95%" bg={modalColor} pb="20px">
        <ModalHeader>Add Product</ModalHeader>
        <ModalCloseButton />
        <ModalBody maxH="80vh" overflowY="auto">
          <ImportClientProductOnly
            cardColor={cardColor}
            textColor={textColor}
            inputBg={inputBg}
            inputText={inputText}
            handlePremium={handlePremium}
            premium={premium}
            handlePremiumDate={handlePremiumDate}
            premiumDate={premiumDate}
            handlePremiumType={handlePremiumType}
            premiumType={premiumType}
            handlePremiumName={handlePremiumName}
            premiumName={premiumName}
            funds={funds}
            fundOptions={fundOptions}
            addFund={addFund}
            removeFund={removeFund}
            selectFund={selectFund}
            selectAllocation={selectAllocation}
            handlePolicyFeeType={handlePolicyFeeType}
            policyFeeType={policyFeeType}
            handleWelcomeBonus={handleWelcomeBonus}
            welcomeBonus={welcomeBonus}
            infoToAddNewProduct={infoToAddNewProduct}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
