/*eslint-disable*/
import { Flex, Link, Text, useColorMode, useColorModeValue } from '@chakra-ui/react';

export default function Footer() {
  const textColor = useColorModeValue('#162744', '#ECEFF1');
  const { toggleColorMode } = useColorMode();
  return (
    <Flex
      zIndex="3"
      flexDirection={{
        base: 'column',
        xl: 'row'
      }}
      alignItems={{
        base: 'center',
        xl: 'start'
      }}
      justifyContent="space-between"
      px={{ base: '30px', md: '50px' }}
      pb="30px"
    >
      <Text
        color={textColor}
        textAlign={{
          base: 'end',
          xl: 'end'
        }}
        mb={{ base: '20px', xl: '0px' }}
      >
        {' '}
        &copy; {1900 + new Date().getYear()}
        <Text as="span" fontWeight="500" ms="4px">
          Advisor Clique. Made by
          <Link
            mx="3px"
            color={textColor}
            href="https://www.SyganEngineeringSolutions.com"
            target="_blank"
            fontWeight="700"
          >
            Sygan Engineering Solutions
          </Link>
        </Text>
      </Text>
      {/* <List display="flex">
        <ListItem
          me={{
            base: '20px',
            md: '44px'
          }}
        >
          <Link
            fontWeight="500"
            color={textColor}
            href="https://www.greateasternlife.com/sg/en/privacy-and-security-policy.html"
          >
            Privacy and Security
          </Link>
        </ListItem>
        <ListItem
          me={{
            base: '20px',
            md: '44px'
          }}
        >
          <Link
            fontWeight="500"
            color={textColor}
            href="https://www.greateasternlife.com/sg/en/terms-and-conditions.html"
          >
            Terms and Conditions
          </Link>
        </ListItem>
        <ListItem
          me={{
            base: '20px',
            md: '44px'
          }}
        >
          <Link
            fontWeight="500"
            color={textColor}
            href="https://www.greateasternlife.com/sg/en/other-policies-and-notices.html"
          >
            Other Policies
          </Link>
        </ListItem>
      </List> */}
    </Flex>
  );
}
