/* eslint-disable */
import {
  Box,
  Button,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import ConfirmModal from 'components/confirmModal/ConfirmModal';
import { useState } from 'react';

import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import { changePassword } from 'service/api';

export default function ChangePasswordForm(props) {
  const textColor = useColorModeValue('#162744', '#ECEFF1');
  const textColorSecondary = 'gray.400';
  const bgButton = useColorModeValue('#47abff', '#47ABFF');
  const bgHover = useColorModeValue({ bg: 'blue.400' }, { bg: 'blue.400' });
  const buttonTextColor = useColorModeValue('#ECEFF1', '#162744');
  const borderColor = useColorModeValue('#162744', '#ECEFF1');

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  const [show2, setShow2] = useState(false);
  const handleClick2 = () => setShow2(!show2);

  const [show3, setShow3] = useState(false);
  const handleClick3 = () => setShow3(!show3);

  const [oldPassword, setOldPassword] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [message, setMessage] = useState('Your password will be updated after clicking confirm!');
  const [disableConfirm, setDisableConfirm] = useState(false);

  const handleOldPassword = (event) => {
    setOldPassword(event.target.value);
  };

  const handlePassword1 = (event) => {
    setPassword1(event.target.value);
  };

  const handlePassword2 = (event) => {
    setPassword2(event.target.value);
  };

  const handleOpenModal = async () => {
    if (password1 !== password2) {
      setMessage('Password does not match!');
      setDisableConfirm(true);
    } else if (password1 === '') {
      setMessage('Password is empty!');
      setDisableConfirm(true);
    } else {
      setMessage('Your password will be updated after clicking confirm!');
      setDisableConfirm(false);
    }
    onOpen();
  };

  const handleSubmit = async () => {
    try {
      await changePassword(password1);
      setMessage('Password successfully updated!');
      const reload = () => {
        window.location.reload(true);
      };
      setTimeout(reload, 1000);
    } catch (err) {
      alert(err);
    }
  };

  return (
    <Card
      direction="column"
      w="100%"
      p="0px"
      mb="48px"
      overflowX={{ sm: 'scroll', lg: 'hidden' }}
      border={`1px solid ${borderColor}`}
    >
      <Box direction="column" w="100%" padding="20px" borderRadius="8px">
        <Text
          color={textColor}
          fontSize="16px"
          fontWeight="700"
          lineHeight="100%"
          marginBottom="0.625rem"
        >
          Old/Temporary Password
        </Text>
        <InputGroup size="md">
          <Input
            isRequired={true}
            fontSize="sm"
            placeholder="Min. 8 characters"
            mb="24px"
            size="lg"
            type={show ? 'text' : 'password'}
            variant="auth"
            value={oldPassword}
            onChange={handleOldPassword}
            borderRadius="30px"
          />
          <InputRightElement display="flex" alignItems="center" mt="4px">
            <Icon
              color={textColorSecondary}
              _hover={{ cursor: 'pointer' }}
              as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
              onClick={handleClick}
            />
          </InputRightElement>
        </InputGroup>

        <Text
          color={textColor}
          fontSize="16px"
          fontWeight="700"
          lineHeight="100%"
          marginBottom="0.625rem"
        >
          New Password
        </Text>
        <InputGroup size="md">
          <Input
            isRequired={true}
            fontSize="sm"
            placeholder="Min. 8 characters"
            mb="24px"
            size="lg"
            type={show2 ? 'text' : 'password'}
            variant="auth"
            value={password1}
            onChange={handlePassword1}
            borderRadius="30px"
          />
          <InputRightElement display="flex" alignItems="center" mt="4px">
            <Icon
              color={textColorSecondary}
              _hover={{ cursor: 'pointer' }}
              as={show2 ? RiEyeCloseLine : MdOutlineRemoveRedEye}
              onClick={handleClick2}
            />
          </InputRightElement>
        </InputGroup>

        <Text
          color={textColor}
          fontSize="16px"
          fontWeight="700"
          lineHeight="100%"
          marginBottom="0.625rem"
        >
          Confirm Password
        </Text>
        <InputGroup size="md">
          <Input
            isRequired={true}
            fontSize="sm"
            placeholder="Min. 8 characters"
            mb="24px"
            size="lg"
            type={show3 ? 'text' : 'password'}
            variant="auth"
            value={password2}
            onChange={handlePassword2}
            borderRadius="30px"
          />
          <InputRightElement display="flex" alignItems="center" mt="4px">
            <Icon
              color={textColorSecondary}
              _hover={{ cursor: 'pointer' }}
              as={show3 ? RiEyeCloseLine : MdOutlineRemoveRedEye}
              onClick={handleClick3}
            />
          </InputRightElement>
        </InputGroup>

        <Button
          bg={bgButton}
          color={buttonTextColor}
          _hover={bgHover}
          width="100%"
          onClick={handleOpenModal}
        >
          Change Password
        </Button>
        <ConfirmModal
          isOpen={isOpen}
          onClose={onClose}
          onSubmit={handleSubmit}
          message={message}
          disabled={disableConfirm}
          buttonText="Continue editing"
        />
      </Box>
    </Card>
  );
}
