import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  Text,
  useColorModeValue
} from '@chakra-ui/react';

import DefaultAuth from 'layouts/auth/Default';

import illustration2 from 'assets/img/auth/Group 8.png';
import illustration from 'assets/img/auth/Group 9.png';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import { adminLogin, login } from 'service/api';

function SignIn() {
  const location = useLocation();
  const isAdmin = location.pathname.includes('admin');

  const textColor = useColorModeValue('navy.700', 'white.100');
  const textColorSecondary = 'white.100';
  const textColorDetails = useColorModeValue('navy.700', 'secondaryGray.600');
  const textColorBrand = useColorModeValue('brand.500', 'white.100');
  const brandStars = useColorModeValue('brand.500', 'brand.400');
  const bgButton = useColorModeValue('#47abff', '#47ABFF');
  const bgHover = useColorModeValue({ bg: 'blue.400' }, { bg: 'blue.400' });
  const buttonTextColor = useColorModeValue('#ECEFF1', '#162744');

  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const handleEmail = (event) => {
    setEmail(event.target.value);
  };

  const handlePassword = (event) => {
    setPassword(event.target.value);
  };

  const handleLogin = async () => {
    setLoading(true);
    try {
      if (isAdmin) {
        await adminLogin(email, password);
      } else {
        await login(email, password);
      }
      const homePage = isAdmin
        ? `${process.env.PUBLIC_URL}/admin/default`
        : `${process.env.PUBLIC_URL}/agent/default`;
      window.location.replace(homePage);
    } catch (err) {
      alert(err);
    } finally {
      setLoading(false);
    }
  };

  const illustrationImg = isAdmin ? illustration2 : illustration;

  return (
    <DefaultAuth illustrationBackground={illustrationImg} image={illustrationImg} isSignIn={true}>
      <Flex
        maxW={{ base: '100%', md: 'max-content' }}
        w="100%"
        mx={{ base: 'auto', lg: '0px' }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: '30px', md: '60px' }}
        px={{ base: '25px', md: '0px' }}
        mt={{ base: '40px', md: '14vh' }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px" fontFamily="exo">
            Sign In ({isAdmin ? 'Admin' : 'Agent'})
          </Heading>
          <Text mb="36px" ms="4px" color={textColorSecondary} fontWeight="400" fontSize="md">
            Enter your email and password to sign in!
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: '100%', md: '420px' }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: 'auto', lg: 'unset' }}
          me="auto"
          mb={{ base: '20px', md: 'auto' }}
        >
          <FormControl>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Email<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: '0px', md: '0px' }}
              type="email"
              placeholder="sample@email.com"
              mb="24px"
              fontWeight="500"
              size="lg"
              onChange={handleEmail}
              value={email}
            />
            <FormLabel ms="4px" fontSize="sm" fontWeight="500" color={textColor} display="flex">
              Password<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size="md">
              <Input
                isRequired={true}
                fontSize="sm"
                placeholder="Min. 8 characters"
                mb="24px"
                size="lg"
                type={show ? 'text' : 'password'}
                variant="auth"
                onChange={handlePassword}
                value={password}
              />
              <InputRightElement display="flex" alignItems="center" mt="4px">
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: 'pointer' }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>
            <Flex justifyContent="space-between" align="center" mb="24px">
              <FormControl display="flex" alignItems="center">
                <Checkbox id="remember-login" me="10px" />
                <FormLabel
                  htmlFor="remember-login"
                  mb="0"
                  fontWeight="normal"
                  color={textColor}
                  fontSize="sm"
                >
                  Keep me logged in
                </FormLabel>
              </FormControl>
              {!isAdmin && (
                <NavLink to="/auth/forgot-password">
                  <Text color={textColorBrand} fontSize="sm" w="124px" fontWeight="500">
                    Forgot password?
                  </Text>
                </NavLink>
              )}
            </Flex>
            <Flex justifyContent="space-between" alignItems="center" mb="24px">
              {loading ? (
                <Flex w="100%" justify="center" align="center" h="50px" mb="24px">
                  <Spinner
                    size="lg"
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color={bgButton}
                  />
                </Flex>
              ) : (
                <Button
                  fontSize="sm"
                  variant="brand"
                  fontWeight="500"
                  w="100%"
                  h="50px"
                  mb="24px"
                  onClick={handleLogin}
                  _hover={bgHover}
                  bg={bgButton}
                  color={buttonTextColor}
                  shadow="none"
                >
                  Sign In
                </Button>
              )}
            </Flex>
          </FormControl>
          {!isAdmin && (
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="start"
              maxW="100%"
              mt="0px"
            >
              <Text color={textColorDetails} fontWeight="400" fontSize="14px">
                Not registered yet?
                <NavLink to={isAdmin ? '/auth/sign-up-admin' : '/auth/sign-up'}>
                  <Text
                    color={textColorBrand}
                    as="span"
                    ms="5px"
                    fontWeight="500"
                    textDecorationLine="underline"
                  >
                    Create an Account
                  </Text>
                </NavLink>
              </Text>
            </Flex>
          )}
          {isAdmin && (
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="start"
              maxW="100%"
              mt="12px"
            >
              <NavLink to={isAdmin ? '/auth/sign-in' : '/auth/sign-in-admin'}>
                <Text color={textColorBrand} as="span" fontWeight="500" fontSize="14px">
                  {isAdmin ? 'Login as Agent' : 'Login as Admin'}
                </Text>
              </NavLink>
            </Flex>
          )}
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;
