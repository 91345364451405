import {
  Box,
  Button,
  Icon,
  SimpleGrid,
  Spinner,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';
import ConfirmModal from 'components/confirmModal/ConfirmModal';
import BasicTableWithPagination from 'components/table/BasicTableWithPagination';
import { useEffect, useState } from 'react';
import { MdDelete } from 'react-icons/md';
import { getAgentClientsWithStats, getProduct, getSelfAgentId, removeClient } from 'service/api';
import { formatDateStrict } from 'utils/string';
import { columnsClients } from 'views/agent/clients/variables/columnsData';
import tableDataClients from 'views/agent/clients/variables/tableClients.json';

export default function Clients() {
  const [clients, setClients] = useState(tableDataClients);
  const [deleteIdx, setDeleteIdx] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const spinnerColor = useColorModeValue('#47abff', '#47ABFF');

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const applyClients = async (arr) => {
        const res = [];
        for (let i = 0; i < arr.length; i++) {
          const productIds = arr[i]['productIds'];
          const productNames = await Promise.all(
            productIds.map(async (id) => {
              const p = await getProduct(id);
              return p['name'];
            })
          );

          res.push({
            id: arr[i]['id'],
            name: arr[i]['name'],
            start_date: formatDateStrict(arr[i]['startDate']),
            total_invested: arr[i]['totalInvested'].toFixed(2),
            aum: arr[i]['totalAssetValue'].toFixed(2),
            xirr: arr[i]['xirr'].toFixed(2),
            roi: arr[i]['roi'].toFixed(2),
            products: productNames.join(', '),
            delete: (
              <Button
                p="0"
                bg="transparent"
                onClick={() => {
                  onOpen();
                  setDeleteIdx(arr[i]['id']);
                }}
              >
                <Icon as={MdDelete} h="20px" w="20px" />
              </Button>
            )
          });
        }
        return res;
      };
      const c = await getAgentClientsWithStats(getSelfAgentId(), 100, 0);
      setClients(await applyClients(c));
      setIsLoading(false);
    };

    fetchData();
  }, []);

  const handleDeleteClient = async () => {
    try {
      await removeClient(parseInt(deleteIdx));
      window.location.reload(true);
    } catch (err) {
      alert(err);
    }
  };

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <Spinner
            size="xl"
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color={spinnerColor}
          />
        </Box>
      ) : (
        <SimpleGrid mb="20px" columns={{ sm: 1, md: 1 }} spacing={{ base: '20px', xl: '20px' }}>
          <BasicTableWithPagination
            columnsData={columnsClients}
            tableData={clients}
            title="Clients Overview"
          />
        </SimpleGrid>
      )}
      <ConfirmModal
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={handleDeleteClient}
        message="Your selected client will be deleted after clicking confirm!"
        buttonText="Cancel"
      />
    </Box>
  );
}
