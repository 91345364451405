import { Box, Flex, SimpleGrid, Spinner, useColorModeValue } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import MiniCalendar from 'components/calendar/MiniCalendar';
import tableClientAlerts from 'views/agent/dashboard/variables/tableClientAlerts.json';
import tableClientsOverview from 'views/agent/dashboard/variables/tableClientsOverview.json';
import tableClientTransactions from 'views/agent/dashboard/variables/tableClientTransactions.json';
import tablePerformingFunds from 'views/agent/dashboard/variables/tablePerformingFunds.json';
import PerformingFundTable from './components/PerformingFundTable';
import {
  columnsAlert,
  columnsClientsOverview,
  columnsClientTransactions,
  columnsPerformingFund
} from './variables/columnsData';

import BasicTableWithPagination from 'components/table/BasicTableWithPagination';
import {
  formatFundName,
  getAgentClientsWithStats,
  getClientTransactions,
  getFundsByPerformance,
  getSelfAgentId,
  getTransactions,
  getTriggeredAgentAlerts
} from 'service/api';
import { numberWithCommas } from 'utils/numbers';
import { formatDateStrict } from 'utils/string';

export default function MainDashboard() {
  const [worstFunds, setWorstFunds] = useState(tablePerformingFunds);
  const [bestFunds, setBestFunds] = useState(tablePerformingFunds);
  const [clients, setClients] = useState(tableClientsOverview);
  const [transactions, setTransactions] = useState(tableClientTransactions);
  const [date, setDate] = useState(new Date());
  const [alerts, setAlerts] = useState(tableClientAlerts);
  const [isLoading, setLoading] = useState(false);

  const spinnerColor = useColorModeValue('#47abff', '#47ABFF');

  const applyFunds = (arr) =>
    arr.map((fund, index) => ({
      rank: index + 1,
      name: formatFundName(fund['FundID']),
      change: `${fund['BidPricePctDiff'].toFixed(2)}%`
    }));

  const applyClients = (arr) => {
    return arr.map((client) => ({
      name: client['name'],
      hash: client['hash'],
      start_date: formatDateStrict(client['startDate']),
      total_invested: client['totalInvested'].toFixed(2),
      aum: client['totalAssetValue'].toFixed(2),
      xirr: client['xirr'].toFixed(2),
      roi: client['roi'].toFixed(2)
    }));
  };

  const applyTransactions = (arr, clientIdToNameDic) => {
    return arr.map((transaction) => {
      return {
        id: transaction['ID'],
        type:
          transaction['type'] === 'automatic' || transaction['type'] === 'imported'
            ? transaction['type']
            : 'manually',
        client: clientIdToNameDic[transaction['clientId']],
        amount: transaction['amount'].toFixed(2),
        date: formatDateStrict(transaction['CreatedAt'])
      };
    });
  };
  const applyAlerts = (arr) =>
    arr.map((alert) => ({
      id: alert['ID'],
      name: formatFundName(alert['fundId']),
      price: numberWithCommas(alert['price']),
      date: formatDateStrict(alert['CreatedAt']),
      triggered_date: formatDateStrict(alert['triggeredAt'])
    }));

  const fetchTransactions = async (clientsIds) => {
    const uniqueClientsIds = Array.from(new Set(clientsIds.map(JSON.stringify))).map(JSON.parse);

    const transactions = await Promise.all(
      uniqueClientsIds.map(async (ids) => {
        const clientId = ids[0];
        const manualTransactions = await getClientTransactions(clientId, 100, 0);
        const productsIds = ids[1];

        const importedTransactions = await Promise.all(
          productsIds.map((productId) =>
            getTransactions(clientId, productId).catch((err) => {
              console.log(err, 'ERROR');
              return [];
            })
          )
        );

        return [...manualTransactions, ...importedTransactions.flat()];
      })
    );
    return transactions.flat();
  };

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      setLoading(true);
      try {
        const [fundsWorst, fundsBest, clientsData, alertsData] = await Promise.all([
          getFundsByPerformance(10, 0, 'weekly', true),
          getFundsByPerformance(10, 0, 'weekly', false),
          getAgentClientsWithStats(getSelfAgentId(), 100, 0),
          getTriggeredAgentAlerts(getSelfAgentId(), 100, 0)
        ]);

        if (isMounted) {
          setWorstFunds(applyFunds(fundsWorst));
          setBestFunds(applyFunds(fundsBest));
          const clients = applyClients(clientsData);
          const clientIdToNameDic = clientsData.reduce((acc, client) => {
            acc[client.id] = client.name;
            return acc;
          }, {});
          setClients(clients);
          const clientsIds = clientsData.map((client) => [client.id, client.productIds]);
          console.log(clientsIds);
          const importedTransactions = await fetchTransactions(clientsIds);

          const sortedTransactions = importedTransactions.toSorted((a, b) => {
            const aDate = new Date(a.CreatedAt);
            const bDate = new Date(b.CreatedAt);
            return bDate - aDate;
          });

          const transactions = applyTransactions(sortedTransactions, clientIdToNameDic);
          setTransactions(transactions);

          setAlerts(applyAlerts(alertsData));
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      setLoading(false);
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, []);

  const handleFundsUpdate = async (period, isWorst) => {
    const funds = await getFundsByPerformance(10, 0, period, isWorst);
    isWorst ? setWorstFunds(applyFunds(funds)) : setBestFunds(applyFunds(funds));
  };

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      {isLoading ? (
        <Flex justify="center" align="center" height="100vh">
          <Spinner
            size="xl"
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color={spinnerColor}
          />
        </Flex>
      ) : (
        <>
          <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
            <BasicTableWithPagination
              columnsData={columnsClientsOverview}
              tableData={clients}
              title="Clients Overview"
            />
            <BasicTableWithPagination
              columnsData={columnsClientTransactions}
              tableData={transactions}
              title="Recent Client Transactions"
            />
          </SimpleGrid>

          <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap="20px" mb="20px">
            <PerformingFundTable
              columnsData={columnsPerformingFund}
              tableData={bestFunds}
              type="Best"
              lastUpdatedDate={new Date().toLocaleDateString('en-GB').replace(/\//g, '-')}
              handleWeekly={() => handleFundsUpdate('weekly', false)}
              handleMonthly={() => handleFundsUpdate('monthly', false)}
              handleYearly={() => handleFundsUpdate('yearly', false)}
              handleYtd={() => handleFundsUpdate('ytd', false)}
            />
            <PerformingFundTable
              columnsData={columnsPerformingFund}
              tableData={worstFunds}
              type="Worst"
              lastUpdatedDate={new Date().toLocaleDateString('en-GB').replace(/\//g, '-')}
              handleWeekly={() => handleFundsUpdate('weekly', true)}
              handleMonthly={() => handleFundsUpdate('monthly', true)}
              handleYearly={() => handleFundsUpdate('yearly', true)}
              handleYtd={() => handleFundsUpdate('ytd', true)}
            />
          </SimpleGrid>

          <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap="20px" mb="20px">
            <MiniCalendar
              h="100%"
              minW="100%"
              selectRange={false}
              date={date}
              onDateChange={setDate}
            />
            <BasicTableWithPagination
              columnsData={columnsAlert}
              tableData={alerts}
              title="Triggered Alerts"
            />
          </SimpleGrid>
        </>
      )}
    </Box>
  );
}
