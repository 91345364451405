import {
  Button,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useColorModeValue
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { MdRefresh } from 'react-icons/md';
import { deleteManuallTransaction, getClientTransactions } from 'service/api';
import { convertDateToRender } from 'utils/string';

export const TransactionAuditModal = ({ isOpen, onClose, transactions, isLoading, clientId }) => {
  const bgButton = useColorModeValue('#47abff', '#47ABFF');
  const bgHover = useColorModeValue({ bg: 'blue.400' }, { bg: 'blue.400' });
  const buttonTextColor = useColorModeValue('#ECEFF1', '#162744');
  const cardColor = useColorModeValue('#ECEFF1', '#162744');
  const borderColor = useColorModeValue('#162744', '#ECEFF1');

  const [manualTransactions, setManualTransactions] = useState([]);

  useEffect(() => {
    if (isOpen) {
      const fetchManualTransactions = async () => {
        try {
          const manualTransactions = await getClientTransactions(clientId, 100, 0);
          const updatedManualTransactions = manualTransactions.map((transaction) => ({
            ...transaction,
            type: 'manually',
            applicationType: transaction.type,
            priceEffectiveDate: transaction.CreatedAt
          }));
          setManualTransactions(updatedManualTransactions);
        } catch (error) {
          console.error('Failed to fetch manual transactions:', error);
        }
      };

      fetchManualTransactions();
    }
  }, [isOpen]);

  const undoTransaction = async (transactionId) => {
    try {
      await deleteManuallTransaction(transactionId);
      window.location.reload(true);
    } catch (error) {
      console.error('Failed to undo transaction:', error);
    }
  };

  const allTransactions = [...manualTransactions, ...transactions];

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        maxW="740px"
        borderRadius="20px"
        bg={cardColor}
        border={`1px solid ${borderColor}`}
      >
        <ModalHeader fontSize="24px" fontWeight="400" pb="0px">
          Review audit history
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody maxH="500px" overflowY="auto" p="0px">
          {isLoading ? (
            <Grid placeItems="center" height="200px">
              <Spinner size="xl" />
            </Grid>
          ) : (
            <Grid templateColumns="270px 175px 200px 90px" rowGap="3px">
              {/* Table Headers */}
              <Text mb="7px" align="center" color="#A0AEBF" borderBottom="1px solid #A0AEBF">
                Application Type
              </Text>
              <Text color="#A0AEBF" mb="7px" align="center" borderBottom="1px solid #A0AEBF">
                Date
              </Text>
              <Text color="#A0AEBF" mb="7px" align="center" borderBottom="1px solid #A0AEBF">
                Transaction Type
              </Text>
              <Text color="#A0AEBF" mb="7px" align="center" borderBottom="1px solid #A0AEBF">
                Undo
              </Text>

              {/* Transactions */}
              {allTransactions.length > 0 ? (
                allTransactions.map((transaction, index) => (
                  <React.Fragment key={transaction.id}>
                    <Text alignSelf="center" align="center">
                      {transaction.applicationType}
                    </Text>
                    <Text alignSelf="center" align="center">
                      {convertDateToRender(transaction.priceEffectiveDate)}
                    </Text>
                    <Text alignSelf="center" align="center">
                      {transaction.type}
                    </Text>
                    {index === 0 && transaction.type === 'manually' ? (
                      <Button
                        alignSelf="center"
                        justifySelf="start"
                        onClick={() => undoTransaction(transaction.ID)}
                        h="100%"
                        fontSize="18px"
                        bg="transparent"
                        p="0"
                        align="center"
                      >
                        <MdRefresh />
                      </Button>
                    ) : (
                      <Text alignSelf="center"></Text>
                    )}
                  </React.Fragment>
                ))
              ) : (
                <Text>No transactions found</Text>
              )}
            </Grid>
          )}
        </ModalBody>

        <ModalFooter alignItems="center" justifyContent="center">
          <Button
            alignSelf="center"
            bg={bgButton}
            onClick={onClose}
            _hover={bgHover}
            color={buttonTextColor}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
